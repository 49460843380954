import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, PercentPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { MaterialModule} from './material/material/material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
//import { HomeComponent } from './components/pages/home/home.component';
//import { ProductsComponent } from './components/pages/products/products.component';
//import { ContactComponent } from './components/pages/contact/contact.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HomeRoutingModule } from './components/pages/home/home-routing.module';
import { ProductsRoutingModule } from './components/pages/products/products-routing.module';
import { ContactRoutingModule } from './components/pages/contact/contact-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AboutRoutingModule } from './components/pages/about/about-routing.module';
import { NewsComponent } from './components/bpages/news-center/components/news/news.component';
import { LoginComponent } from './modules/login/login.component';
import { EWalletLoginComponent } from './modules/ewallet-login/ewallet-login.component';
import { RegisterComponent } from './modules/register/register.component';
import { DonateComponent } from './components/pages/donate/donate.component';
import { NewsRoutingModule } from './components/pages/news/news-routing.module';
import { DonateRoutingModule } from './components/pages/donate/donate-routing.module';
import { MainRoutingModule } from './components/bpages/main/main-routing.module';
import { RecaptchaModule } from "ng-recaptcha";
import { RECAPTCHA_LANGUAGE } from "ng-recaptcha";
import { RecaptchaComponent } from 'ng-recaptcha';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module,ReCaptchaV3Service } from 'ng-recaptcha';
import { ToastrModule } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { NgSpcModule } from 'ng-spc';
import { NgxPayPalModule } from 'ngx-paypal';
// Import the library
import { NgxStripeModule } from 'ngx-stripe';

import { SecretQuestionComponent } from './modules/secret-question/secret-question.component';
import { CompleteInfoComponent } from './modules/complete-info/complete-info.component';
import { CartComponent } from './modules/cart/cart.component';
import { ReceiveCoinsComponent } from './modules/receive-coins/receive-coins.component';
import { AccountLockedComponent } from './modules/account-locked/account-locked.component';
import { ActivateAccountComponent } from './modules/activate-account/activate-account.component';
import { ProductComponent } from './modules/product/product.component';
import { PrivacyTermsComponent } from './modules/privacy-terms/privacy-terms.component';
import { UserTermsComponent } from './modules/user-terms/user-terms.component';
import { DistributorTermsComponent } from './modules/distributor-terms/distributor-terms.component';
import { SecretQuestionSettingComponent } from './modules/secret-question-setting/secret-question-setting.component';
import { ConfirmDialogComponent } from './modules/confirm-dialog/confirm-dialog.component';
import { AutocompleteOffDirective } from './directives/autocomplete-off.directive';
import { HeaderscrollDirective } from './headerscroll.directive';
import { ConfirmWithdrawalRequestComponent } from './modules/confirm-withdrawal-request/confirm-withdrawal-request.component';
import { BulletinBoardComponent } from './components/bpages/news-center/components/bulletin-board/bulletin-board.component';
import { ConventionAndEventsComponent } from './components/bpages/news-center/components/convention-and-events/convention-and-events.component';
import { PerformanceGrowthBulletinComponent } from './components/bpages/news-center/components/performance-growth-bulletin/performance-growth-bulletin.component';
import { PromotionComponent } from './components/bpages/news-center/components/promotion/promotion.component';
import { BonusOverviewComponent } from './components/bpages/bonus-and-commission/components/bonus-overview/bonus-overview.component';
import { CurrentBalanceComponent } from './components/bpages/bonus-and-commission/components/current-balance/current-balance.component';
import { IncomeFlowRecordComponent } from './components/bpages/bonus-and-commission/components/income-flow-record/income-flow-record.component';
import { MonthlyBonusRecordComponent } from './components/bpages/bonus-and-commission/components/monthly-bonus-record/monthly-bonus-record.component';
import { UserProfileComponent } from './components/bpages/my-account/components/user-profile/user-profile.component';
import { AccountRankComponent } from './components/bpages/my-account/components/account-rank/account-rank.component';
import { AccountPwChangeComponent } from './components/bpages/my-account/components/account-pw-change/account-pw-change.component';
import { SecurityQuestionsSettingComponent } from './components/bpages/my-account/components/security-questions-setting/security-questions-setting.component';
import { AccountUploadPortraitComponent } from './components/bpages/my-account/components/account-upload-portrait/account-upload-portrait.component';
import { DownlineRanksComponent } from './components/bpages/team/components/downline-ranks/downline-ranks.component';
import { PersonallySponsoredChartComponent } from './components/bpages/team/components/personally-sponsored-chart/personally-sponsored-chart.component';
import { PersonallySponsoredListComponent } from './components/bpages/team/components/personally-sponsored-list/personally-sponsored-list.component';
import { TreeComponent } from './components/bpages/team/components/tree/tree.component';
import { PersonalNewOrdersComponent } from './components/bpages/orders/components/personal-new-orders/personal-new-orders.component';
import { PersonalOrdersRecordComponent } from './components/bpages/orders/components/personal-orders-record/personal-orders-record.component';
import { TeamNewOrdersComponent } from './components/bpages/orders/components/team-new-orders/team-new-orders.component';
import { TeamOrdersRecordComponent } from './components/bpages/orders/components/team-orders-record/team-orders-record.component';
import { QuillModule } from 'ngx-quill';
import { WebcamModule } from 'ngx-webcam';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { CompanyMessagesComponent } from './components/bpages/user-help/components/company-messages/company-messages.component';
import { ByteTokenTransfersComponent } from './components/bpages/user-help/components/byte-token-transfers/byte-token-transfers.component';
import { MyRequestsComponent } from './components/bpages/user-help/components/my-requests/my-requests.component';
import { CustomerServiceComponent } from './components/bpages/user-help/components/customer-service/customer-service.component';
import { UserFaqsComponent } from './components/bpages/user-help/components/user-faqs/user-faqs.component';
import { ApplicationFormsComponent } from './components/bpages/user-help/components/application-forms/application-forms.component';
import { AccountComponent } from './components/bpages/ewallet/components/account/account.component';
import { BalanceRecordComponent } from './components/bpages/ewallet/components/balance-record/balance-record.component';
import { EwalletBalanceComponent } from './components/bpages/ewallet/components/ewallet-balance/ewallet-balance.component';
import { EwalletOverviewComponent } from './components/bpages/ewallet/components/ewallet-overview/ewallet-overview.component';
import { PurchaseByteTokensComponent } from './components/bpages/ewallet/components/purchase-byte-tokens/purchase-byte-tokens.component';
import { RenewEwalletPasswordComponent } from './components/bpages/ewallet/components/renew-ewallet-password/renew-ewallet-password.component';
import { TradePasswordComponent } from './components/bpages/ewallet/components/trade-password/trade-password.component';
import { TransferToCurrentBalanceComponent } from './components/bpages/ewallet/components/transfer-to-current-balance/transfer-to-current-balance.component';
import { WithdrawalRequestComponent } from './components/bpages/ewallet/components/withdrawal-request/withdrawal-request.component';
import { WithdrawalRequestHistoryComponent } from './components/bpages/ewallet/components/withdrawal-request-history/withdrawal-request-history.component';
import { DashboardComponent } from './components/bpages/dashboard/dashboard.component';
import { PurchaseComponent } from './components/bpages/purchase/purchase.component';
import { PayMonthlyFeeComponent } from './components/bpages/pay-monthly-fee/pay-monthly-fee.component';
import { SetUpMonthlyPaymentComponent } from './components/bpages/set-up-monthly-payment/set-up-monthly-payment.component';
import { DailySalesReportComponent } from './components/bpages/admin-pages/sales-report/components/daily-sales-report/daily-sales-report.component';
import { WeeklySalesReportComponent } from './components/bpages/admin-pages/sales-report/components/weekly-sales-report/weekly-sales-report.component';
import { MonthlySalesReportComponent } from './components/bpages/admin-pages/sales-report/components/monthly-sales-report/monthly-sales-report.component';
import { QuarterlySalesReportComponent } from './components/bpages/admin-pages/sales-report/components/quarterly-sales-report/quarterly-sales-report.component';
import { AnnualSalesReportComponent } from './components/bpages/admin-pages/sales-report/components/annual-sales-report/annual-sales-report.component';
import { DailyBonusPayoutReportComponent } from './components/bpages/admin-pages/commision-and-bonus-payout-reports/components/daily-bonus-payout-report/daily-bonus-payout-report.component';
import { WeeklyBonusPayoutReportComponent } from './components/bpages/admin-pages/commision-and-bonus-payout-reports/components/weekly-bonus-payout-report/weekly-bonus-payout-report.component';
import { QuarterlyBonusPayoutReportComponent } from './components/bpages/admin-pages/commision-and-bonus-payout-reports/components/quarterly-bonus-payout-report/quarterly-bonus-payout-report.component';
import { MonthlyBonusPayoutReportComponent } from './components/bpages/admin-pages/commision-and-bonus-payout-reports/components/monthly-bonus-payout-report/monthly-bonus-payout-report.component';
import { AnnualBonusPayoutReportComponent } from './components/bpages/admin-pages/commision-and-bonus-payout-reports/components/annual-bonus-payout-report/annual-bonus-payout-report.component';
import { CustomerRequestsComponent } from './components/bpages/admin-pages/customer-support/components/customer-requests/customer-requests.component';
import { UserApplicationsComponent } from './components/bpages/admin-pages/customer-support/components/user-applications/user-applications.component';
import { PostEditBulletinBoardComponent } from './components/bpages/admin-pages/customer-support/components/post-edit-bulletin-board/post-edit-bulletin-board.component';
import { PostEditCompanyNewsComponent } from './components/bpages/admin-pages/customer-support/components/post-edit-company-news/post-edit-company-news.component';
import { PostEditConventionsAndEventsComponent } from './components/bpages/admin-pages/customer-support/components/post-edit-conventions-and-events/post-edit-conventions-and-events.component';
import { PostEditMessageToCustomerComponent } from './components/bpages/admin-pages/customer-support/components/post-edit-message-to-customer/post-edit-message-to-customer.component';
import { PostEditPromotionsComponent } from './components/bpages/admin-pages/customer-support/components/post-edit-promotions/post-edit-promotions.component';
import { GrowthBonusFundsPoolComponent } from './components/bpages/admin-pages/performance-growth-bonus-setting/components/growth-bonus-funds-pool/growth-bonus-funds-pool.component';
import { GrowthBonusTopWinnersComponent } from './components/bpages/admin-pages/performance-growth-bonus-setting/components/growth-bonus-top-winners/growth-bonus-top-winners.component';
import { PublishAndDistributeBonusPgComponent } from './components/bpages/admin-pages/performance-growth-bonus-setting/components/publish-and-distribute-bonus-pg/publish-and-distribute-bonus-pg.component';
import { WinnersListRecordByMonthPgComponent } from './components/bpages/admin-pages/performance-growth-bonus-setting/components/winners-list-record-by-month-pg/winners-list-record-by-month-pg.component';
import { LeadershipBonusFundsPoolComponent } from './components/bpages/admin-pages/global-leadership-bonus-setting/components/leadership-bonus-funds-pool/leadership-bonus-funds-pool.component';
import { LeadershipBonusTopWinnersComponent } from './components/bpages/admin-pages/global-leadership-bonus-setting/components/leadership-bonus-top-winners/leadership-bonus-top-winners.component';
import { PublishAndDistributeBonusGlComponent } from './components/bpages/admin-pages/global-leadership-bonus-setting/components/publish-and-distribute-bonus-gl/publish-and-distribute-bonus-gl.component';
import { WinnersListRecordByMonthGlComponent } from './components/bpages/admin-pages/global-leadership-bonus-setting/components/winners-list-record-by-month-gl/winners-list-record-by-month-gl.component';
import { CancelUserOrderComponent } from './components/bpages/admin-pages/accounting/components/cancel-user-order/cancel-user-order.component';
import { CancelledOrdersMonthlyRecordComponent } from './components/bpages/admin-pages/accounting/components/cancelled-orders-monthly-record/cancelled-orders-monthly-record.component';
import { TransferMonthlyRecordComponent } from './components/bpages/admin-pages/accounting/components/transfer-monthly-record/transfer-monthly-record.component';
import { UserRequestListComponent } from './components/bpages/admin-pages/accounting/components/user-request-list/user-request-list.component';
import { WithdrawForUserComponent } from './components/bpages/admin-pages/accounting/components/withdraw-for-user/withdraw-for-user.component';
import { WithdrawalMonthlyRecordComponent } from './components/bpages/admin-pages/accounting/components/withdrawal-monthly-record/withdrawal-monthly-record.component';
import { ChangeUserRankComponent } from './components/bpages/admin-pages/super/components/change-user-rank/change-user-rank.component';
import { LockUnlockUserAccountComponent } from './components/bpages/admin-pages/super/components/lock-unlock-user-account/lock-unlock-user-account.component';
import { ProductSettingComponent } from './components/bpages/admin-pages/super/components/product-setting/product-setting.component';
import { RankReportsComponent } from './components/bpages/admin-pages/super/components/rank-reports/rank-reports.component';
import { UserDownlinesLimitComponent } from './components/bpages/admin-pages/super/components/user-downlines-limit/user-downlines-limit.component';
import { AdditionalBonusFundsPoolComponent } from './components/bpages/admin-pages/additional-bonus-setting/components/additional-bonus-funds-pool/additional-bonus-funds-pool.component';
import { PublishAdditionalBonusComponent } from './components/bpages/admin-pages/additional-bonus-setting/components/publish-additional-bonus/publish-additional-bonus.component';
import { PublishAdditionalBonusHistoryComponent } from './components/bpages/admin-pages/additional-bonus-setting/components/publish-additional-bonus-history/publish-additional-bonus-history.component';
import { AddProductReviewComponent } from './modules/add-product-review/add-product-review.component';
import { StarRatingComponent } from './modules/star-rating/star-rating.component';
import { StarRatingDetailComponent } from './modules/star-rating-detail/star-rating-detail.component';
import { CouponSetupComponent } from './components/bpages/admin-pages/customer-support/components/coupon-setup/coupon-setup.component';
import { CustomerReviewsComponent } from './modules/customer-reviews/customer-reviews.component';
import { UserManagerComponent } from './components/bpages/admin-pages/super/components/user-manager/user-manager.component';
import { PrintDeliveryTicketComponent } from './components/bpages/admin-pages/customer-support/components/print-delivery-ticket/print-delivery-ticket.component';
import { VerifyDialogComponent } from './modules/verify-dialog/verify-dialog.component';
import { AlertDialogComponent } from './modules/alert-dialog/alert-dialog.component';
import { BonusByteTokenTransfersComponent } from './components/bpages/bonus-and-commission/byte-token-transfers/bonus-byte-token-transfers.component';
import { DailyTaxesReportComponent } from './components/bpages/admin-pages/taxes-report/components/daily-taxes-report/daily-taxes-report.component';
import { WeeklyTaxesReportComponent } from './components/bpages/admin-pages/taxes-report/components/weekly-taxes-report/weekly-taxes-report.component';
import { QuarterlyTaxesReportComponent } from './components/bpages/admin-pages/taxes-report/components/quarterly-taxes-report/quarterly-taxes-report.component';
import { MonthlyTaxesReportComponent } from './components/bpages/admin-pages/taxes-report/components/monthly-taxes-report/monthly-taxes-report.component';
import { AnnualTaxesReportComponent } from './components/bpages/admin-pages/taxes-report/components/annual-taxes-report/annual-taxes-report.component';
import { RefundByteTokensComponent } from './components/bpages/admin-pages/accounting/components/refund-byte-tokens/refund-byte-tokens.component';
import { InputDialogComponent } from './modules/input-dialog/input-dialog.component';
import { SalesInvoiceComponent } from './components/bpages/admin-pages/taxes-report/components/sales-invoice/sales-invoice.component';
import { ForgotEwalletPasswordComponent } from './modules/forgot-ewallet-password/forgot-ewallet-password.component';
import { SendBatchEmailComponent } from './components/bpages/admin-pages/super/components/send-batch-email/send-batch-email.component';
import { SelectInputDialogComponent } from './modules/select-input-dialog/select-input-dialog.component';
import { AccountRankDialogComponent } from './modules/account-rank-dialog/account-rank-dialog.component';
import { SystemMaintenanceComponent } from './components/bpages/admin-pages/super/components/system-maintenance/system-maintenance.component';
import { WholeOrganizationalChartComponent } from './components/bpages/admin-pages/super/components/whole-organizational-chart/whole-organizational-chart.component';
import { PersonalWebpageComponent } from './components/bpages/team/components/personal-webpage/personal-webpage.component';
import { InvoiceLinkComponent } from './components/bpages/admin-pages/super/components/invoice-link/invoice-link.component';
import { SendInvoiceComponent } from './components/bpages/admin-pages/super/components/invoice-link/send-invoice/send-invoice.component';
import { WholesaleComponent } from './components/bpages/admin-pages/super/components/wholesale/wholesale.component';
import { EditWholesaleComponent } from './components/bpages/admin-pages/super/components/wholesale/edit-wholesale/edit-wholesale.component';
import { OnlinePaymentComponent } from './components/pages/online-payment/online-payment.component';
import { PaymentComponent } from './modules/payment/payment.component';
import { InvoiceComponent } from './modules/invoice/invoice.component';
import { PintInvoiceComponent } from './modules/pdf-reports/pint-invoice/pint-invoice.component';
import { ByteCartComponent } from './components/pages/byte-cart/byte-cart.component';
import { MyShoppingStoreComponent } from './components/bpages/purchase/components/my-shopping-store/my-shopping-store.component';
import { ChoicColorComponent } from './modules/choic-color/choic-color.component';
import { ImageListComponent } from './modules/image-list/image-list.component';
import { ProfileUpdatedHistoryComponent } from './modules/profile-updated-history/profile-updated-history.component';
import { WithdrawalRequestsComponent } from './components/bpages/admin-pages/accounting/components/withdrawal-requests/withdrawal-requests.component';
import { ViewAssetsComponent } from './components/bpages/admin-pages/super/components/view-assets/view-assets.component';
import { VerifyBalanceDetailComponent } from './modules/verify-balance-detail/verify-balance-detail.component';
import { UploadDocComponent } from './components/bpages/admin-pages/customer-support/components/upload-doc/upload-doc.component';
import { AddUploadDocComponent } from './components/bpages/admin-pages/customer-support/components/upload-doc/add-upload-doc/add-upload-doc.component';
import {FileUploadModule} from 'ng2-file-upload';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { RunAutoshipComponent } from './components/bpages/admin-pages/accounting/components/run-autoship/run-autoship.component';
import { ProductViewComponent } from './components/bpages/purchase/components/product-view/product-view.component';
import { ImgViewDirective } from './directives/img-view.directive';
import { SetAutoshipComponent } from './components/bpages/my-account/components/set-autoship/set-autoship.component';
import { ProductDetailComponent } from './components/bpages/purchase/components/product-detail/product-detail.component';
import { SoldOrdersComponent } from './components/bpages/admin-pages/sales-report/components/sold-orders/sold-orders.component';
import { OrderDetailComponent } from './modules/order-detail/order-detail.component';
import { ShippingAddressesComponent } from './modules/shipping-addresses/shipping-addresses.component';
import { CreditCardInfoComponent } from './modules/credit-card-info/credit-card-info.component';
import { ByteShopComponent } from './components/pages/byte-shop/byte-shop.component';
import { CartViewComponent } from './modules/cart-view/cart-view.component';
import { ViewAirPuifierComponent } from './modules/views/view-air-puifier/view-air-puifier.component';
import { ViewFulvicAcidComponent } from './modules/views/view-fulvic-acid/view-fulvic-acid.component';
import { ViewHydrogenEnergyComponent } from './modules/views/view-hydrogen-energy/view-hydrogen-energy.component';
import { ViewProductsComponent } from './modules/views/view-products/view-products.component';
import { ViewWasherComponent } from './modules/views/view-washer/view-washer.component';
import { environment } from 'src/environments/environment';
import { ViewPetC60Component } from './modules/views/view-pet-c60/view-pet-c60.component';
import { ViewCarbon60Component } from './modules/views/view-carbon60/view-carbon60.component';
import { ViewPetTraceComponent } from './modules/views/view-pet-trace/view-pet-trace.component';
import { CancelOrderComponent } from './components/bpages/admin-pages/customer-support/components/cancel-order/cancel-order.component';
import { BytePaypalComponent } from './modules/byte-paypal/byte-paypal.component';
import { ByteStripeComponent } from './modules/byte-stripe/byte-stripe.component';
import { ViewHydratingSerumComponent } from './modules/views/view-hydrating-serum/view-hydrating-serum.component';
import { ViewFirmingCreamComponent } from './modules/views/view-firming-cream/view-firming-cream.component';
import { ViewNightCreamComponent } from './modules/views/view-night-cream/view-night-cream.component';
import { NightCreamModule } from "./components/pages/skin-care/night-cream/night-cream.module";
import { HydratingSerumModule } from "./components/pages/skin-care/hydrating-serum/hydrating-serum.module";
import { FirmingCreamModule } from "./components/pages/skin-care/firming-cream/firming-cream.module";
import { ViewTintedPoreComponent } from './modules/views/view-tinted-pore/view-tinted-pore.component';
import { TintedPoreModule } from "./components/pages/skin-care/tinted-pore/tinted-pore.module";
import { ViewWrinkleSerumComponent } from './modules/views/view-wrinkle-serum/view-wrinkle-serum.component';
import { WrinkleSerumModule } from "./components/pages/skin-care/wrinkle-serum/wrinkle-serum.module";
//import { TintedPoreComponent } from './components/pages/skin-care/tinted-pore/tinted-pore.component';
//import { NightCreamComponent } from './components/pages/skin-care/night-cream/night-cream.component';
//import { WrinkleSerumComponent } from './components/pages/skin-care/wrinkle-serum/wrinkle-serum.component';
//import { HydratingSerumComponent } from './components/pages/skin-care/hydrating-serum/hydrating-serum.component';
//import { FirmingCreamComponent } from './components/pages/skin-care/firming-cream/firming-cream.component';
//import { PetTraceComponent } from './app/components/pages/pet-trace/pet-trace.component';
//import { Carbon60Component } from './components/pages/carbon60/carbon60.component';
//import { PetC60Component } from './components/pages/pet-c60/pet-c60.component';
//import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
//import { FulvicAcidComponent } from './components/pages/fulvic-acid/fulvic-acid.component';
//import { HydrogenEnergyComponent } from './components/pages/hydrogen-energy/hydrogen-energy.component';
//import { DownloadCentreComponent } from './components/pages/download-centre/download-centre.component';
//import { OnlinePaymentComponent } from './modules/online-payment/online-payment.component';
import { ByteTokenTrxHistoryComponent } from './components/bpages/bonus-and-commission/components/byte-token-trx-history/byte-token-trx-history.component';
import { UseridWebpageComponent } from './components/bpages/team/components/userid-webpage/userid-webpage.component';
import { ShowBytetokenComponent } from './modules/show-bytetoken/show-bytetoken.component';
import { ViewRestorativeComponent } from './modules/views/view-restorative/view-restorative.component';
import { RestorativeModule } from "./components/pages/skin-care/restorative/restorative.module";
import { IntroducePartComponent } from './components/pages/skin-care/common/introduce-part/introduce-part.component';
import { MonthlyUnitSoldComponent } from './components/bpages/admin-pages/sales-report/components/monthly-unit-sold/monthly-unit-sold.component';
//import { RestorativeComponent } from './components/pages/skin-care/restorative/restorative.component';

RecaptchaComponent.prototype.ngOnDestroy = function() {
  if (this.subscription) {
    this.subscription.unsubscribe();
  }
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        RegisterComponent,
        EWalletLoginComponent,
        SecretQuestionComponent,
        CompleteInfoComponent,
        CartComponent,
        ReceiveCoinsComponent,
        AccountLockedComponent,
        ActivateAccountComponent,
        ProductComponent,
        PrivacyTermsComponent,
        UserTermsComponent,
        DistributorTermsComponent,
        SecretQuestionSettingComponent,
        ConfirmDialogComponent,
        AutocompleteOffDirective,
        HeaderscrollDirective,
        ConfirmWithdrawalRequestComponent,
        NewsComponent,
        BulletinBoardComponent,
        ConventionAndEventsComponent,
        PerformanceGrowthBulletinComponent,
        PromotionComponent,
        BonusOverviewComponent,
        CurrentBalanceComponent,
        IncomeFlowRecordComponent,
        MonthlyBonusRecordComponent,
        UserProfileComponent,
        AccountRankComponent,
        AccountPwChangeComponent,
        SecurityQuestionsSettingComponent,
        AccountUploadPortraitComponent,
        DownlineRanksComponent,
        PersonallySponsoredChartComponent,
        PersonallySponsoredListComponent,
        TreeComponent,
        PersonalNewOrdersComponent,
        PersonalOrdersRecordComponent,
        TeamNewOrdersComponent,
        TeamOrdersRecordComponent,
        CompanyMessagesComponent,
        ByteTokenTransfersComponent,
        MyRequestsComponent,
        CustomerServiceComponent,
        UserFaqsComponent,
        ApplicationFormsComponent,
        AccountComponent,
        BalanceRecordComponent,
        EwalletBalanceComponent,
        EwalletOverviewComponent,
        PurchaseByteTokensComponent,
        RenewEwalletPasswordComponent,
        TradePasswordComponent,
        TransferToCurrentBalanceComponent,
        WithdrawalRequestComponent,
        WithdrawalRequestHistoryComponent,
        DashboardComponent,
        PurchaseComponent,
        SetUpMonthlyPaymentComponent,
        PayMonthlyFeeComponent,
        DailyTaxesReportComponent,
        WeeklyTaxesReportComponent,
        MonthlyTaxesReportComponent,
        QuarterlyTaxesReportComponent,
        AnnualTaxesReportComponent,
        DailySalesReportComponent,
        WeeklySalesReportComponent,
        MonthlySalesReportComponent,
        QuarterlySalesReportComponent,
        AnnualSalesReportComponent,
        DailyBonusPayoutReportComponent,
        WeeklyBonusPayoutReportComponent,
        MonthlyBonusPayoutReportComponent,
        QuarterlyBonusPayoutReportComponent,
        AnnualBonusPayoutReportComponent,
        CustomerRequestsComponent,
        UserApplicationsComponent,
        PostEditBulletinBoardComponent,
        PostEditCompanyNewsComponent,
        PostEditConventionsAndEventsComponent,
        PostEditMessageToCustomerComponent,
        PostEditPromotionsComponent,
        GrowthBonusFundsPoolComponent,
        GrowthBonusTopWinnersComponent,
        PublishAndDistributeBonusPgComponent,
        WinnersListRecordByMonthPgComponent,
        LeadershipBonusFundsPoolComponent,
        LeadershipBonusTopWinnersComponent,
        PublishAndDistributeBonusGlComponent,
        WinnersListRecordByMonthGlComponent,
        CancelUserOrderComponent,
        CancelledOrdersMonthlyRecordComponent,
        TransferMonthlyRecordComponent,
        UserRequestListComponent,
        WithdrawForUserComponent,
        WithdrawalMonthlyRecordComponent,
        ChangeUserRankComponent,
        LockUnlockUserAccountComponent,
        ProductSettingComponent,
        RankReportsComponent,
        UserDownlinesLimitComponent,
        AdditionalBonusFundsPoolComponent,
        PublishAdditionalBonusComponent,
        PublishAdditionalBonusHistoryComponent,
        AddProductReviewComponent,
        StarRatingComponent,
        StarRatingDetailComponent,
        CouponSetupComponent,
        CustomerReviewsComponent,
        UserManagerComponent,
        PrintDeliveryTicketComponent,
        VerifyDialogComponent,
        AlertDialogComponent,
        BonusByteTokenTransfersComponent,
        RefundByteTokensComponent,
        InputDialogComponent,
        SalesInvoiceComponent,
        ForgotEwalletPasswordComponent,
        SendBatchEmailComponent,
        SelectInputDialogComponent,
        AccountRankDialogComponent,
        SystemMaintenanceComponent,
        WholeOrganizationalChartComponent,
        PersonalWebpageComponent,
        InvoiceLinkComponent,
        SendInvoiceComponent,
        WholesaleComponent,
        EditWholesaleComponent,
        PaymentComponent,
        InvoiceComponent,
        PintInvoiceComponent,
        ByteCartComponent,
        MyShoppingStoreComponent,
        ChoicColorComponent,
        ImageListComponent,
        ProfileUpdatedHistoryComponent,
        WithdrawalRequestsComponent,
        ViewAssetsComponent,
        VerifyBalanceDetailComponent,
        UploadDocComponent,
        AddUploadDocComponent,
        RunAutoshipComponent,
        ProductViewComponent,
        ImgViewDirective,
        SetAutoshipComponent,
        ProductDetailComponent,
        SoldOrdersComponent,
        OrderDetailComponent,
        ShippingAddressesComponent,
        CreditCardInfoComponent,
        ByteShopComponent,
        CartViewComponent,
        ViewAirPuifierComponent,
        ViewFulvicAcidComponent,
        ViewHydrogenEnergyComponent,
        ViewProductsComponent,
        ViewWasherComponent,
        ViewPetC60Component,
        ViewCarbon60Component,
        ViewPetTraceComponent,
        CancelOrderComponent,
        BytePaypalComponent,
        ByteStripeComponent,
        ViewHydratingSerumComponent,
        ViewFirmingCreamComponent,
        ViewNightCreamComponent,
        ViewTintedPoreComponent,
        ViewWrinkleSerumComponent,
        //TintedPoreComponent,
        //NightCreamComponent,
        //WrinkleSerumComponent,
        //HydratingSerumComponent,
        //FirmingCreamComponent,
        //PetTraceComponent,
        //Carbon60Component,
        //PetC60Component,
        //TestimonialsComponent,
        //FulvicAcidComponent,
        //HydrogenEnergyComponent,
        //DownloadCentreComponent,
        //OnlinePaymentComponent
        ByteTokenTrxHistoryComponent,
        UseridWebpageComponent,
        ShowBytetokenComponent,
        ViewRestorativeComponent,
        MonthlyUnitSoldComponent,
        //IntroducePartComponent
        //        RestorativeComponent
    ],
    providers: [
        {
            //provide: RECAPTCHA_LANGUAGE,
            //useValue: 'en', // use French language
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.recaptcha.siteKey,
        },
        //ReCaptchaV3Service,
        PercentPipe,
        CurrencyPipe
    ],
    bootstrap: [AppComponent],
    exports: [SecretQuestionComponent, CompleteInfoComponent, AccountLockedComponent, ActivateAccountComponent, ProductComponent, PrivacyTermsComponent, UserTermsComponent, DistributorTermsComponent, SecretQuestionSettingComponent, ConfirmDialogComponent, ConfirmWithdrawalRequestComponent, AddProductReviewComponent, StarRatingComponent, StarRatingDetailComponent, CouponSetupComponent, CustomerReviewsComponent, UserManagerComponent, PrintDeliveryTicketComponent, VerifyDialogComponent, AlertDialogComponent, BonusByteTokenTransfersComponent, RefundByteTokensComponent, InputDialogComponent, SalesInvoiceComponent, ForgotEwalletPasswordComponent, SendBatchEmailComponent, SelectInputDialogComponent, AccountRankDialogComponent, SystemMaintenanceComponent, WholeOrganizationalChartComponent, PersonalWebpageComponent],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxPayPalModule,
        NgxStripeModule.forRoot(environment.stripe_client_key), //'***your-stripe-publishable-key***'),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        HomeRoutingModule,
        ProductsRoutingModule,
        ContactRoutingModule,
        BrowserAnimationsModule,
        AboutRoutingModule,
        NewsRoutingModule,
        DonateRoutingModule,
        MainRoutingModule,
        //RecaptchaModule,
        RecaptchaV3Module,
        ToastrModule.forRoot({ positionClass: 'toast-bottom-right' }),
        NgSpcModule,
        QuillModule.forRoot(),
        WebcamModule,
        NgxJsonViewerModule,
        NgxOrgChartModule,
        ClipboardModule,
        NightCreamModule,
        HydratingSerumModule,
        FirmingCreamModule,
        TintedPoreModule,
        WrinkleSerumModule,
        RestorativeModule
    ]
})
export class AppModule { }
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
