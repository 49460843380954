import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { News } from 'src/app/modules/common/News';
import { User } from 'src/app/modules/common/User';
import { ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-post-edit-company-news',
  templateUrl: './post-edit-company-news.component.html',
  styleUrls: ['./post-edit-company-news.component.scss']
})
export class PostEditCompanyNewsComponent implements OnInit {

  public user: User;
  public newsList : News[] = [];
  public news : News;
  private _unsubscribe$ = new Subject<any>();
  public isCreating : boolean = false;
  public newsForm : FormGroup;
  public toUserId : number;
  panelOpenState = false;
  public isProcessing1 = false;
  public isProcessing2 = false;

  constructor(
    public translate : TranslateConfigService, 
    private ngDialog : NgbModal,
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private router : Router,
    private route: ActivatedRoute,
    private sharedUtilService : SharedUtilService
  ) { }

  onCreate(){
    this.isCreating = true;
  }

  onBack(){
    this.news = null;
    this.isCreating = false;
    this.getNewsList();
    this.openPage('');
  }

  onEdit(index){
    this.news = this.newsList[index];
    this.isCreating = false;
    this.openPage(this.news.id);
  }

  openPage(pId) {
    this.router.navigate(['backmain/admin/post-edit-company-news/' + pId]);
  }

  ngOnInit(): void {
    this.newsForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      content: ['', [Validators.required]],
    });

    
    this.getUser();
    this.getNewsList();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getNewsList()
  {
    this.evgService.getNews({}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.newsList = resp.data[0].results;
          this.route.params.subscribe((params) => {
            
            if(params.id) {
              var news = this.newsList.filter(news => {
                return news.id == params.id
              })

              if(news){
                this.news = news[0];
                this.newsForm.patchValue({
                  title : this.news.title,
                  content : this.news.content,
               });
              };
            }
          });
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  postNews(newsDirective)
  {
    if(this.newsForm.status === "VALID"){
      let data = this.newsForm.value;
      this.isProcessing1 = true;
      this.evgService.postNews(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if(resp.data[0]){
            //console.log("postNews",resp.data[0]);
            this.sharedUtilService.resetForm(this.newsForm,newsDirective);
            this.onBack();
          }
        }
        this.isProcessing1 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing1 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }

    
  }

  modifyNews(newsDirective)
  {
    if(this.newsForm.status === "VALID"){
      let data = this.newsForm.value;
      data.id = this.news.id;
      this.isProcessing2 = true;
      this.evgService.modifyNews(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if(resp.data[0]){
            //console.log("modifyNews",resp.data[0]);
            this.sharedUtilService.resetForm(this.newsForm,newsDirective);
            this.onBack();
          }
        }
        this.isProcessing2 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing2 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }
  }

  deleteNews(index)
  {
    if(index >=0 && index < this.newsList.length){
      const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.message = this.translate.translate.instant('DELETECONFIRM');
      modalRef.result.then((result) => {
        if(result){
          let data = { id : this.newsList[index].id};
          // this.newsForm.value;
          //data.id = this.news.id;
          this.isProcessing1 = true;
          this.evgService.deleteNews(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
            if (resp) {
              this.responseHandlerService.handleResponse(resp);
              if(resp.data[0]){
                //console.log("modifyNews",resp.data[0]);
                //this.sharedUtilService.resetForm(this.newsForm,newsDirective);
                this.onBack();
              }
            }
            this.isProcessing1 = false;
          },(errorResult) => {
            console.log("System Error", errorResult);
            this.isProcessing1 = false;
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }); 
    
        }
      });
    }
  }

}
