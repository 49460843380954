import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { SelectInputDialogComponent } from 'src/app/modules/select-input-dialog/select-input-dialog.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from 'src/app/modules/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-send-batch-email',
  templateUrl: './send-batch-email.component.html',
  styleUrls: ['./send-batch-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendBatchEmailComponent implements OnInit {

  public user: User;
  public replyMessages : any;
  public replyMessage : any;
  public emailList : any[];
  public distributors : any[];
  public selectedEmail : any;
  private _unsubscribe$ = new Subject<any>();
  public isCreating : boolean = false;
  public emailForm : FormGroup;
  panelOpenState = false;
  public isProcessing = false;
  public testEmail: string='';

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private router : Router,
    private route: ActivatedRoute,
    private cdr : ChangeDetectorRef,
    private ngDialog : NgbModal
  ) { }

  ngOnInit(): void {
    this.initForms();
    this.getUser();
    this.getEmailList();
    this.getDistributors();

  }

  initForms()
  {
    this.emailForm = this.formBuilder.group({
      email_subject: ['', [Validators.required]],
      email_body: ['', [Validators.required]],
    });
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }
  onCreate(){
    this.isCreating = true;
  }

  onEdit(index){
    this.selectedEmail = this.emailList[index];
    this.isCreating = true;
    this.emailForm.patchValue({
      'email_subject' : this.selectedEmail.email_subject,
      'email_body' : this.selectedEmail.email_body
    })
  }

  getEmailList()
  {
    this.evgService.getEmailList({}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.success){
          this.emailList = resp.data[0].results;
          this.cdr.detectChanges();
          //console.log(this.emailList);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getDistributors()
  {
    this.evgService.getDistributors({}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.success){
          this.distributors = resp.data[0].results;
          this.distributors = this.distributors.map(element => {
            return {userId : element.id, email : element.email , first_name : element.first_name, last_name : element.last_name};
          })
          this.cdr.detectChanges();
          //console.log(this.distributors);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getEmailById(id)
  {
    this.evgService.getEmailById({id : id}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.success){
          this.selectedEmail = resp.data[0].results;
          //console.log(this.selectedEmail);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }
  

  postEmail()
  {
    if(this.emailForm.valid){
      if(this.emailForm.value.email_body){
        var data;
        if(this.selectedEmail){
          data = {
            id : this.selectedEmail.id,
            email_subject : this.emailForm.value.email_subject,
            email_body : this.emailForm.value.email_body,
          }
        }else{
          data = this.emailForm.value;
        }
        this.shareinfoService.setCreditProcess(true);
        this.evgService.postEmail(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
            if (resp) {
              this.responseHandlerService.handleResponse(resp);
              this.shareinfoService.setCreditProcess(false);
              if(resp.success){
                this.selectedEmail = resp.data[0].results;
                this.onBack();
              }
              //console.log(resp);
            }
          },(errorResult) => {
            this.shareinfoService.setCreditProcess(false);
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }); 
      }else{
        alert('Content is required!');
      }
     }
       
  }

  onBack(){
    this.selectedEmail = null;
    this.isCreating = false;
    this.getEmailList();
  }

  onSendAllDistributors(index)
  {
    const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: 'md', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.message = this.translate.translate.instant('SENDALLDISTRIB') + "?";
    modalRef.result.then((result) => {
      if(result) {
        this.evgService.sendEmailToAll(this.emailList[index]).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }); 
      }
    });
  }

  onSendNormalAccounts(index)
  {
    const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: 'md', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.message = this.translate.translate.instant('SENDEMAILUNLOCKED') + "?";
    modalRef.result.then((result) => {
      if(result) {
        this.evgService.sendEmailToNormalAccounts(this.emailList[index]).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }); 
      }
    });
  }

  onSendSelectedDistributors(index)
  {
    const modalRef = this.ngDialog.open(SelectInputDialogComponent, { windowClass: 'select-input-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.fieldName = this.translate.translate.instant('USERS');
    modalRef.componentInstance.options = this.distributors;
    modalRef.result.then((result) => {
        //console.log(result);
        if(result){
          let data = {
          id : this.emailList[index].id,
          email_subject : this.emailList[index].email_subject,
          email_body : this.emailList[index].email_body,
          users : result
        }
        this.evgService.sendEmailToUsers(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }); 
        }
    });
  }
  onSendDraftToTest(index) {
    //console.log('this.testEmail:',this.testEmail);
    if (this.testEmail && this.testEmail.length>0) {
      if (this.testEmail.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        let data = {
          id : this.emailList[index].id,
          email_subject : this.emailList[index].email_subject,
          email_body : this.emailList[index].email_body,
          email : this.testEmail
        }
        this.evgService.sendEmailToTests(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }); 
      } else {
        const modalRef = this.ngDialog.open(AlertDialogComponent, { windowClass: 'alert-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
        modalRef.componentInstance.translate = this.translate;
        modalRef.componentInstance.message = this.translate.translate.instant('EMAILISVALID');
      }
    }
  }

}
