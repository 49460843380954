import { PercentPipe } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { CartItem } from '../common/CartItem';
import { User } from '../common/User';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DistributorTermsComponent } from '../distributor-terms/distributor-terms.component';
import { VerifyDialogComponent } from '../verify-dialog/verify-dialog.component';
//import { eventNames } from 'process';
import { environment } from 'src/environments/environment';
//import { ICreateOrderRequest } from 'ngx-paypal';
//import { render } from 'creditcardpayments/creditCardPayments';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartComponent implements OnInit {

  @Input() translate : TranslateConfigService;
  //@Input() step : number = 1;

  public user : User;
  public resetLinkSent : boolean = false;
  public checkoutForm : FormGroup;
  public distrib_form : FormGroup;
  public step : number = 1;
  public countries : Array<any>=[];
  public provinces : Array<any>=[];
  public contact_countries : Array<any>=[];
  public contact_provinces : Array<any>=[];
  public cart_items : CartItem[] = [];
  public cart_total : number = 0;
  public cart_tax : number = 0;
  public cart_tax_1 : number = 0;
  public cart_tax_2 : number = 0;
  public cart_tax_rate : any = [];
  public cart_subtotal : number = 0;
  public cart_discounts : number = 0;
  public cart_item_selected : number = 0;
  public cart_discount_rate : number =  0;
  public tax_rates : any = [];
  private _unsubscribe$ = new Subject<any>();
  public selectedCountry : {
    name : '',
    code : ''
  };
  public preferredShipping : number = 2;
  public shippingAddresses : any[] = [];
  public shippingOptions : any = [];
  public shippingOptionsCache : any = [];
  public selectedAddress : number = -1;
  public coupon = '';
  public appliedCoupon : any;
  public appliedCommonCoupon : any=null;
  public verified = false;
  public common_discount:number=0.0;
  public common_discount_rate:number=0.0;
  public autoship_discount:number=0.0;
  public autoship_discount_rate:number=0.0;
  //public termsForAutoship:Array<any>=[];
  public termsForAutoship:any=[];
  public expiryDateValue:string='';
  public sameasbillingadr:boolean=true;
  public I_am_Distributor : boolean=false;
  public isTestmode:boolean = environment.production;
  public creditCardNumber: string='';
  public shipping_fee:number=0.0;
  public my_tokens:number=0;
  public payby_currency:string="USD";
  public selected_tax_rate:any=null;
  showSuccess: boolean;
  public payPalConfig:any=null;
  public shippingInfo: any=null;
  public pay_type:number=null; // 0: credit card, 1, Tokens, 2: paypal
  public lastContactInfo:any=null;
  public lastShippingInfo:any=null;
  public payPal_amount=0;
  public selectedShippingOption: string='';
  //{ MemberNo: number; first_name: any; last_name: any; email: any; phone: any; country: any; province: any; city: any; address: any; postCode: any; };
  

  constructor(
    public activeModal : NgbActiveModal, 
    private router: Router,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,
    private cdref : ChangeDetectorRef,
    private ngDialog : NgbModal,
    private sharedUtilService : SharedUtilService,
    private percentPipe : PercentPipe
  ) { 
    this.getTaxRates();
    this.getLastContactInfo();
  }


  ngOnInit(): void {
    //this.initConfig();

    this.getTermAutoship();
    this.initializeForms();
    //this.getUser();
    //this.getCommonCoupon();
    this.getUser();

    this.getCountryList();
    this.getCart(1);
    this.getUserShippingAddresses();
        
  }

  onKeydown(ele) {
    if (! ( ele.key>='0' && ele.key<='9' || ele.key=='Backspace' || ele.key=='Delete' || ele.key=='Enter' || ele.key=='ArrowLeft' || ele.key=='ArrowRight' || ele.key=='End' || ele.key=='Home')) {
      ele.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  modifyInput(ele) {
    if (this.expiryDateValue.length==2) {
      if (! (ele.key =='Backspace' || ele.key =='Delete')) {
        this.expiryDateValue += '/';
      }
    }
  }

  cc_format(ele) {
    //console.log('cc_format value', value);
    let value = this.creditCardNumber;
    if (value) {
      const v = value.toString().replace(/\s+/g, '').replace(/[^0-9]/gi, '');
      const matches = v.match(/\d{4,16}/g);
      const match = (matches && matches[0]) || '';
      const parts = [];
      //console.log('cc_format match: v: matches:', match,v, matches);
      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }
      //console.log('cc_format parts:', parts);
      if (parts.length > 0) {
        this.creditCardNumber = parts.join(' ');
      } else {
        this.creditCardNumber = value;
      }
      //console.log('cc_format creditCardNumber:', this.creditCardNumber);
    }
  }

  onKeydownForTel(ele) {
    if (! ( ele.key>='0' && ele.key<='9' || ele.key<='-' || ele.key=='Backspace' || ele.key=='Delete' || ele.key=='Enter' || ele.key=='ArrowLeft' || ele.key=='ArrowRight' || ele.key=='End' || ele.key=='Home')) {
      ele.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  getParcelChar(pId:number) {
    return pId <= 7?1:0;
  }

  isFreeShipping() {
    const freeShippingItems = [-1];
    let flag = 1;
    let total = 0;
    let count = 0;
    
    this.cart_items.forEach(e=>{
      
      if(freeShippingItems.indexOf(e.pId) === -1 ) {
        flag = 0;
      }
     
      if(this.getParcelChar(e.pId)==0) {
        total += (e.unitPrice + (e.apply_benefit==1?e.benefit_value : 0)) * e.nums;
        count ++;
      }
    });  
    
    if(total >= 500 && count==this.cart_items.length) 
      flag = 1;
    
    return flag;
  }

  initializeForms(){
    //this.UsersControl = this.formBuilder.control('', [Validators.maxLength(100)]);

    this.checkoutForm = this.formBuilder.group({
      //name: ['', Validators.required],
      contact_first_name:['', Validators.required],
      contact_last_name:['', Validators.required],
      contact_address : ['', Validators.required],
      contact_city : ['', Validators.required],
      contact_email : ['',[Validators.required, Validators.email]],
      //contact_phone : ['', [Validators.required, Validators.pattern('/^(?=(?:\D*\d){10,15}\D*$)\+?[0-9]{1,3}[\s-]?(?:\(0?[0-9]{1,5}\)|[0-9]{1,5})[-\s]?[0-9][\d\s-]{5,7}\s?(?:x[\d-]{0,4})?$/')]],
      contact_phone : ['', Validators.required],
      contact_province : ['', Validators.required],
      contact_country : ['', Validators.required],
      contact_postCode : ['', Validators.required],
      //Email: ['', [Validators.required, Validators.email]],
      Tel : [''],
      Address : [''],
      City : [''],
      Province : [''],
      Country : [''],
      PostCode : [''],
      payType : [0, Validators.required],
      cardNo : ['',Validators.required],
      expireDate : ['',Validators.required],
      cvCode : ['',Validators.required],
      firstName : ['',Validators.required],
      lastName : ['',Validators.required],
      //postCode : ['',Validators.required],
      first_name : [''],
      last_name : [''],
      address : [''],
      Referrals : [''],
      Recommended : [''],
      selectedShippingOption : ['', Validators.required]
    });

    this.distrib_form = this.formBuilder.group({
      isDistributor : ['']
    });

    this.checkoutForm.get('payType').valueChanges
    .subscribe(value => {
      console.log('this.checkoutForm.get(payType).valueChanges value',value);
      this.pay_type=value;
      if(value == 0 ) {
        this.checkoutForm.get('cardNo').setValidators(Validators.required);
        this.checkoutForm.get('expireDate').setValidators(Validators.required);
        this.checkoutForm.get('cvCode').setValidators(Validators.required);
        this.checkoutForm.get('firstName').setValidators(Validators.required);
        this.checkoutForm.get('lastName').setValidators(Validators.required);
        //this.checkoutForm.get('postCode').setValidators(Validators.required);
        //this.checkoutForm.get('address').setValidators(Validators.required);
        // this.checkoutForm.get('firstName').setValue(this.user.first_name);
        // this.checkoutForm.get('lastName').setValue(this.user.last_name);
        // this.checkoutForm.get('postCode').setValue(this.checkoutForm.get('PostCode').value);
        // this.checkoutForm.get('address').setValue(this.checkoutForm.get('Address').value);

        this.checkoutForm.markAsPristine();
      } else {
        this.checkoutForm.get('cardNo').clearValidators();
        this.checkoutForm.get('expireDate').clearValidators();
        this.checkoutForm.get('cvCode').clearValidators();
        this.checkoutForm.get('firstName').clearValidators();
        this.checkoutForm.get('lastName').clearValidators();
        //this.checkoutForm.get('postCode').clearValidators();
        //this.checkoutForm.get('address').clearValidators();
      }
      this.checkoutForm.controls['cardNo'].updateValueAndValidity();
      this.checkoutForm.controls['expireDate'].updateValueAndValidity();
      this.checkoutForm.controls['cvCode'].updateValueAndValidity();
      this.checkoutForm.controls['firstName'].updateValueAndValidity();
      this.checkoutForm.controls['lastName'].updateValueAndValidity();
      //this.checkoutForm.controls['postCode'].updateValueAndValidity();
      //this.checkoutForm.controls['address'].updateValueAndValidity();
    });

    this.checkoutForm.get('Country').valueChanges
    .subscribe(value => {

      if(value){
        this.getProvinceList(value,0);
        if(this.shippingAddresses.length > 0){
          this.checkoutForm.patchValue({
            Province : ''
          });
        }
      }
    });

    this.checkoutForm.get('Province').valueChanges
    .subscribe(value => {
      if (this.sameasbillingadr==false) {
        if(value){
          this.cart_tax_rate = this.tax_rates.find(rate => {
            return rate.state_name.toUpperCase() === value.toUpperCase()
          })
          this.shipping_fee = this.cart_tax_rate && this.cart_tax_rate.shipping_fee ? this.cart_tax_rate.shipping_fee : 0;
          this.payby_currency = this.cart_tax_rate && this.cart_tax_rate.currency_code ? this.cart_tax_rate.currency_code : 'USD';
          this.collectPaymentInfo(this.cart_tax_rate,1);
          this.getCart();
        }else{
          this.cart_tax_rate = null;
          this.shipping_fee =0;
          this.getCart();
        }
      }
    });


    this.checkoutForm.get('contact_country').valueChanges
    .subscribe(value => {

      if(value){
        this.getProvinceList(value,1);
        if(this.shippingAddresses.length > 0){
          this.checkoutForm.patchValue({
            contact_province : ''
          });
        }
      }
    });

    this.checkoutForm.get('contact_province').valueChanges
    .subscribe(value => {
      if (this.sameasbillingadr) {
        if(value){
          this.cart_tax_rate = this.tax_rates.find(rate => {
            return rate.state_name.toUpperCase() === value.toUpperCase()
          })
          this.shipping_fee = this.cart_tax_rate && this.cart_tax_rate.shipping_fee ? this.cart_tax_rate.shipping_fee : 0;
          this.payby_currency = this.cart_tax_rate && this.cart_tax_rate.currency_code ? this.cart_tax_rate.currency_code : 'USD';
          this.collectPaymentInfo(this.cart_tax_rate,1);
          
          this.getCart();
        }else{
          this.cart_tax_rate = null;
          this.shipping_fee =0;
          this.getCart();
        }
      }
    });

    this.checkoutForm.get('Referrals').valueChanges
    .subscribe(value => {
        this.verified = false;
      
    });

    this.checkoutForm.get('Recommended').valueChanges
    .subscribe(value => {

      this.verified = false;
    });

    //david added to calculate shipping fee
    this.checkoutForm.get('PostCode').valueChanges.pipe(
      debounceTime(2000), // Wait for 1 sec while the user is typing
    ).subscribe(value => {

      if(value == this.checkoutForm.get('contact_postCode').value)
        return;

      //this.shipping_fee = 0;
      if(value){
        this.getShippingFee(value, this.checkoutForm.get('Country').value);
        //this.shipping_fee = this.shippingOptions[0].priceDetails.due; 
      }
      //this.getCart();

    });
  
    this.checkoutForm.get('selectedShippingOption').valueChanges
    .subscribe(value => {
      if(value.length && this.shippingOptions && this.shippingOptions[+value]) {
        this.shipping_fee = parseFloat(this.shippingOptions[+value].priceDetails.due);
        this.getCart();
      }else{
        this.shipping_fee = 0;
      }
      
    });  

    this.checkoutForm.get('contact_postCode').valueChanges.pipe(
      debounceTime(2000), // Wait for 1 sec while the user is typing
    ).subscribe(value => {

      //this.shipping_fee = 0;
      if(value && this.preferredShipping !== 0 && this.preferredShipping !== 1){
        this.getShippingFee(value, this.checkoutForm.get('contact_country').value);         
      }
      //this.getCart();
    });
  }

  contact_info_isNG() {
   return ( this.checkoutForm.get('contact_first_name').hasError('required') ||
            this.checkoutForm.get('contact_last_name').hasError('required') ||
            this.checkoutForm.get('contact_email').hasError('required') ||
            this.checkoutForm.get('contact_phone').hasError('required') ||
            this.checkoutForm.get('contact_country').hasError('required') ||
            this.checkoutForm.get('contact_province').hasError('required') ||
            this.checkoutForm.get('contact_city').hasError('required') ||
            this.checkoutForm.get('contact_address').hasError('required') ||
            this.checkoutForm.get('contact_postCode').hasError('required'));

  }
  show_contact_info_isNG() {
    console.log('contact_first_name:', this.checkoutForm.get('contact_first_name').hasError('required'));
    console.log('contact_last_name:', this.checkoutForm.get('contact_last_name').hasError('required'));
    console.log('contact_email:', this.checkoutForm.get('contact_email').hasError('required'));
    console.log('contact_email:', this.checkoutForm.get('contact_email').hasError('email'));
    console.log('contact_phone:', this.checkoutForm.get('contact_phone').hasError('required'));
    //console.log('contact_phone:', this.checkoutForm.get('contact_phone').hasError('pattern'));
    console.log('contact_country:', this.checkoutForm.get('contact_country').hasError('required'));
    console.log('contact_province:', this.checkoutForm.get('contact_province').hasError('required'));
    console.log('contact_city:', this.checkoutForm.get('contact_city').hasError('required'));
    console.log('contact_address:', this.checkoutForm.get('contact_address').hasError('required'));
    console.log('contact_postCode:', this.checkoutForm.get('contact_postCode').hasError('required'));
    //console.log('Email:', this.checkoutForm.get('Email').hasError('required'));
    //console.log('Email:', this.checkoutForm.get('Email').hasError('email'));

    console.log('first_name:', this.checkoutForm.get('first_name').hasError('required'));
    console.log('last_name:', this.checkoutForm.get('last_name').hasError('required'));
    console.log('Tel:', this.checkoutForm.get('Tel').hasError('required'));
    //console.log('Tel:', this.checkoutForm.get('Tel').hasError('pattern'));
    console.log('Address:', this.checkoutForm.get('Address').hasError('required'));
    console.log('City:', this.checkoutForm.get('City').hasError('required'));
    console.log('Province:', this.checkoutForm.get('Province').hasError('required'));
    console.log('Country:', this.checkoutForm.get('Country').hasError('required'));
    console.log('PostCode:', this.checkoutForm.get('PostCode').hasError('required'));

    console.log('payType:', this.checkoutForm.get('payType').hasError('required'));
    console.log('cardNo:', this.checkoutForm.get('cardNo').hasError('required'));
    console.log('expireDate:', this.checkoutForm.get('expireDate').hasError('required'));
    console.log('cvCode:', this.checkoutForm.get('cvCode').hasError('required'));
    console.log('firstName:', this.checkoutForm.get('firstName').hasError('required'));
    console.log('lastName:', this.checkoutForm.get('lastName').hasError('required'));
    //console.log('postCode:', this.checkoutForm.get('postCode').hasError('required'));
   }
 
  preferredShippingChanged(setSelect=0)
  {

    if(this.preferredShipping == 1){//add new address
      
      this.sameasbillingadr=false;
      
      if(this.shippingAddresses.length > 0){
        this.checkoutForm.patchValue({
          Tel : null,
          Address : null,
          City : null,
          Province : null,
          Country : null,
          PostCode : null,
          first_name : null,
          last_name : null,
    
        });
      }
      
      this.checkoutForm.get('Tel').setValidators(Validators.required);
      //this.checkoutForm.get('Email').setValidators(Validators.required);
      this.checkoutForm.get('Address').setValidators(Validators.required);
      this.checkoutForm.get('City').setValidators(Validators.required);
      this.checkoutForm.get('Province').setValidators(Validators.required);
      this.checkoutForm.get('Country').setValidators(Validators.required);
      this.checkoutForm.get('PostCode').setValidators(Validators.required);
      this.checkoutForm.get('first_name').setValidators(Validators.required);
      this.checkoutForm.get('last_name').setValidators(Validators.required);

  
      this.checkoutForm.markAsPristine();

      this.checkoutForm.controls['Tel'].updateValueAndValidity();
      //this.checkoutForm.controls['Email'].updateValueAndValidity();
      this.checkoutForm.controls['Address'].updateValueAndValidity();
      this.checkoutForm.controls['City'].updateValueAndValidity();
      this.checkoutForm.controls['Province'].updateValueAndValidity();
      this.checkoutForm.controls['Country'].updateValueAndValidity();
      this.checkoutForm.controls['PostCode'].updateValueAndValidity();
      this.checkoutForm.controls['first_name'].updateValueAndValidity();
      this.checkoutForm.controls['last_name'].updateValueAndValidity();

      this.selectedAddress = -1;
      //this.selectedAddressChanged();

      if(this.checkoutForm.get('Country').value && this.checkoutForm.get('Province').value){
        this.cart_tax_rate = this.tax_rates.find(rate => {
          return (rate.country.toUpperCase() === (this.checkoutForm.get('Country').value).toUpperCase() && rate.state_name.toUpperCase() === (this.checkoutForm.get('Province').value).toUpperCase()) 
        })
        //this.shipping_fee = this.cart_tax_rate && this.cart_tax_rate.shipping_fee ? this.cart_tax_rate.shipping_fee : 0;
        this.payby_currency = this.cart_tax_rate && this.cart_tax_rate.currency_code ? this.cart_tax_rate.currency_code : 'USD';
        this.collectPaymentInfo(this.cart_tax_rate,1);
        
        this.getCart();
      }else{
        this.cart_tax_rate = null;
        this.shipping_fee=0;
        this.getCart();
      }

      
      this.cdref.detectChanges();
    }else if (this.preferredShipping == 0) {  // selected existing address
      this.sameasbillingadr=false;
      this.checkoutForm.get('Tel').clearValidators();
      //this.checkoutForm.get('Email').clearValidators();
      this.checkoutForm.get('Address').clearValidators();
      this.checkoutForm.get('City').clearValidators();
      this.checkoutForm.get('Province').clearValidators();
      this.checkoutForm.get('Country').clearValidators();
      this.checkoutForm.get('PostCode').clearValidators();
      this.checkoutForm.get('first_name').clearValidators();
      this.checkoutForm.get('last_name').clearValidators();

      this.checkoutForm.markAsPristine();

      this.checkoutForm.controls['Tel'].updateValueAndValidity();
      //this.checkoutForm.controls['Email'].updateValueAndValidity();
      this.checkoutForm.controls['Address'].updateValueAndValidity();
      this.checkoutForm.controls['City'].updateValueAndValidity();
      this.checkoutForm.controls['Province'].updateValueAndValidity();
      this.checkoutForm.controls['Country'].updateValueAndValidity();
      this.checkoutForm.controls['PostCode'].updateValueAndValidity();
      this.checkoutForm.controls['first_name'].updateValueAndValidity();
      this.checkoutForm.controls['last_name'].updateValueAndValidity();

      this.cdref.detectChanges();
      if (setSelect==0) this.selectedAddress = -1;
      this.selectedAddressChanged();
    } else {  // same as billing address
      this.sameasbillingadr=true;
      this.checkoutForm.get('Tel').clearValidators();
      //this.checkoutForm.get('Email').clearValidators();
      this.checkoutForm.get('Address').clearValidators();
      this.checkoutForm.get('City').clearValidators();
      this.checkoutForm.get('Province').clearValidators();
      this.checkoutForm.get('Country').clearValidators();
      this.checkoutForm.get('PostCode').clearValidators();
      this.checkoutForm.get('first_name').clearValidators();
      this.checkoutForm.get('last_name').clearValidators();

      this.checkoutForm.markAsPristine();

      this.checkoutForm.controls['Tel'].updateValueAndValidity();
      //this.checkoutForm.controls['Email'].updateValueAndValidity();
      this.checkoutForm.controls['Address'].updateValueAndValidity();
      this.checkoutForm.controls['City'].updateValueAndValidity();
      this.checkoutForm.controls['Province'].updateValueAndValidity();
      this.checkoutForm.controls['Country'].updateValueAndValidity();
      this.checkoutForm.controls['PostCode'].updateValueAndValidity();
      this.checkoutForm.controls['first_name'].updateValueAndValidity();
      this.checkoutForm.controls['last_name'].updateValueAndValidity();

      this.cdref.detectChanges();
      this.selectedAddress = -1;
      //this.selectedAddressChanged();
      this.getShippingFee(this.checkoutForm.get('contact_postCode').value, this.checkoutForm.get('contact_country').value);
      
        if(this.checkoutForm.get('contact_country').value && this.checkoutForm.get('contact_province').value){
          this.cart_tax_rate = this.tax_rates.find(rate => {
            return (rate.country.toUpperCase() === (this.checkoutForm.get('contact_country').value).toUpperCase() && rate.state_name.toUpperCase() === (this.checkoutForm.get('contact_province').value).toUpperCase()) 
          })
          //this.shipping_fee = this.cart_tax_rate && this.cart_tax_rate.shipping_fee ? this.cart_tax_rate.shipping_fee : 0;
          //this.getShippingFee(this.checkoutForm.get('contact_postCode').value, this.checkoutForm.get('contact_country').value);
          this.payby_currency = this.cart_tax_rate && this.cart_tax_rate.currency_code ? this.cart_tax_rate.currency_code : 'USD';
          this.collectPaymentInfo(this.cart_tax_rate,1);
          
          this.getCart();
        }else{
          this.cart_tax_rate = null;
          this.shipping_fee=0;
          this.getCart();
        }
    }
  }

  preferredPayTypeChanged() {
    if (this.pay_type>=0) {
      this.checkoutForm.patchValue({
        payType: this.pay_type
      });      
    }
    if(this.pay_type == 0){//pay by Elavon crdditcard 
    } else if (this.pay_type == 1) { // pay by tokens

    } else if (this.pay_type == 2) { //pay by palpal

    }
    //console.log('preferredPayTypeChanged pay_type:',this.pay_type);
  }

  /**
   * selected shipping address
   */
  selectedAddressChanged()
  {
    if(this.selectedAddress >=0 && this.shippingAddresses[this.selectedAddress]){
      this.cart_tax_rate = this.tax_rates.find(rate => {
        return rate.state_name.toUpperCase() === this.shippingAddresses[this.selectedAddress].Province.toUpperCase()
              && rate.country.toUpperCase() === this.shippingAddresses[this.selectedAddress].Country.toUpperCase()
      })
      this.getShippingFee(this.shippingAddresses[this.selectedAddress].PostCode, this.shippingAddresses[this.selectedAddress].Country);
      //this.shipping_fee = this.cart_tax_rate && this.cart_tax_rate.shipping_fee ? this.cart_tax_rate.shipping_fee : 0;
      this.payby_currency = this.cart_tax_rate && this.cart_tax_rate.currency_code ? this.cart_tax_rate.currency_code : 'USD';
      this.collectPaymentInfo(this.cart_tax_rate,1);
      this.getCart();
    }else{
      this.cart_tax_rate = null;
      this.shipping_fee=0;
      this.getCart();
    }
  }


  getTaxRates(){
    this.evgService.getTaxRates({}).subscribe( resp => {
      if (resp) {
        this.tax_rates = resp.data[0].results;

      }
    },(errorResult) => {
      console.log("System Error", errorResult);
    });
  }
  
  getCommonDiscountAmt() {
    //if ((this.appliedCoupon.category==1 || this.appliedCoupon.for_common==1) && this.appliedCoupon.detail) {
    // for coupon applied
    this.common_discount=0;
    this.common_discount_rate=0;
    if(this.appliedCommonCoupon){ // for common coupon
      //.category==0 || this.appliedCommonCoupon.for_common==1 
      
      if (this.appliedCommonCoupon.special_range=='*' &&  !(this.appliedCommonCoupon.detail)){
        if(this.appliedCommonCoupon.coupon_type == '0'){
          this.common_discount = this.appliedCommonCoupon.coupon_amt;
          
        }else if(this.appliedCommonCoupon.coupon_type == '1'){
          this.common_discount = (this.cart_subtotal * (this.appliedCommonCoupon.coupon_amt/100));

          this.common_discount_rate = this.appliedCommonCoupon.coupon_amt/100;
        }
      } else {
        //this.getDiscountAmt();
        if ( this.appliedCommonCoupon.detail) {
          let discount=0;
          this.appliedCommonCoupon.detail.forEach((element,i)=>{
            let cartList = this.cart_items.filter(item => item.pId == element.MemberNo && item.selected);
            if (cartList && cartList.length>0) {
              cartList.forEach((e,index)=>{
                if (element.coupon_type==0) {
                  discount += e.nums * element.amount;
                } else {
                  discount += (e.nums * e.unitPrice) * element.amount/100.0;
                }
              });
            }
          });
          this.common_discount = discount;
          //console.log("getCommonDiscountAmt this.common_discount", discount);

        }
        //this.cart_discount_rate = 0;
      }
    }
  }

  getDiscountAmt() {
    //if ((this.appliedCoupon.category==1 || this.appliedCoupon.for_common==1) && this.appliedCoupon.detail) {
    // for coupon applied
    this.cart_discounts=0;
    this.cart_discount_rate=0;
    if(this.appliedCoupon){
      //.category==0 || this.appliedCoupon.for_common==1 
      
      if (this.appliedCoupon.special_range=='*' &&  !(this.appliedCoupon.detail)){
        if(this.appliedCoupon.coupon_type == '0'){
          this.cart_discounts = this.appliedCoupon.coupon_amt;

          this.cart_discount_rate = this.cart_discounts / this.cart_subtotal;
          
        }else if(this.appliedCoupon.coupon_type == '1'){
          this.cart_discounts = (this.cart_subtotal * (this.appliedCoupon.coupon_amt/100));

          this.cart_discount_rate = this.appliedCoupon.coupon_amt/100;
        }else{
          this.cart_discounts = 0;
          this.cart_discount_rate = 0;
        }
      } else {
        //this.getDiscountAmt();
        if ( this.appliedCoupon.detail) {
          let discount=0;
          this.appliedCoupon.detail.forEach((element,i)=>{
            //in the coupon detail, saved pId into MemberNo field
            let cartList = this.cart_items.filter(item => item.pId == element.MemberNo && item.selected);
            if (cartList && cartList.length>0) {
              cartList.forEach((e,index)=>{
                if (element.coupon_type==0) {
                  discount += e.nums * element.amount;
                } else {
                  discount += (e.nums * e.unitPrice) * element.amount/100.0;
                }
              });
            }
          });
          this.cart_discounts = discount;
        }
        this.cart_discount_rate = 0;
      }
      //console.log('getDiscountAmt discount:',discount);
    }
  }


  getCommonCoupon() {
    if (this.user.autoship==1) {
      this.evgService.findCommonCoupon({}).subscribe( resp => {
        if (resp && resp.data[0].results) {
          this.appliedCommonCoupon = resp.data[0].results;
          //console.log("getCommonCoupon resp.data[0].results:", resp.data[0].results);
          
          this.getCommonDiscountAmt();
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
      });
    }

  }

  applyCoupon(){
    if(this.coupon){
      this.evgService.submitCoupon({coupon : this.coupon, category : 0}).subscribe( resp => {
        if (resp) {
          //console.log(resp);
          this.responseHandlerService.handleResponse(resp);
          this.appliedCoupon = resp.data[0].results;

          this.getCart();

          this.cdref.detectChanges();
  
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
      });
    }
  }

  getLastShippingAddess(){
    this.evgService.getLastShippingAddress({}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              //console.log("getUserShippingAddresses",successResponse);
              this.lastShippingInfo = successResponse.data[0].results;

              if(this.lastShippingInfo.length > 0){
                let contactInfo=this.lastShippingInfo[0];
                if (contactInfo.invalid==0) {
                  this.checkoutForm.patchValue({
                    contact_email : contactInfo.email,
                    contact_first_name : contactInfo.first_name,
                    contact_last_name : contactInfo.last_name,
                    contact_postCode : contactInfo.postCode,
                    contact_country : contactInfo.country,
                    contact_province : contactInfo.province,
                    contact_city : contactInfo.city,
                    contact_address : contactInfo.address,
                    contact_phone :  contactInfo.phone,
                  });
                }
              }
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );

  }


  getLastContactInfo(){
    this.evgService.getLastContactInfo({}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              //console.log("getUserShippingAddresses",successResponse);
              this.lastContactInfo = successResponse.data[0].results;

              if(this.lastContactInfo.length > 0){
                let contactInfo=this.lastContactInfo[0];
                if (contactInfo.invalid==0) {
                  this.checkoutForm.patchValue({
                    contact_email : contactInfo.email,
                    contact_first_name : contactInfo.first_name,
                    contact_last_name : contactInfo.last_name,
                    contact_postCode : contactInfo.postCode,
                    contact_country : contactInfo.country,
                    contact_province : contactInfo.province,
                    contact_city : contactInfo.city,
                    contact_address : contactInfo.address,
                    contact_phone :  contactInfo.phone,
                  });
                }
              }
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );

  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
              if (successResponse)
              if (this.lastContactInfo==null || this.shippingAddresses.length<=0) {
                this.checkoutForm.patchValue({
                  contact_email : this.user.email,
                  contact_first_name : this.user.first_name,
                  contact_last_name : this.user.last_name,
                  contact_postCode : this.user.PostCode,
                  contact_country : this.user.Country,
                  contact_province : this.user.Province,
                  contact_city : this.user.City,
                  contact_address : this.user.Address,
                  contact_phone :  this.user.Tel,
                });
              } else {
                let contactInfo = this.lastContactInfo[0];
                if (contactInfo.invalid==0) {
                  this.checkoutForm.patchValue({
                    contact_email : contactInfo.email,
                    contact_first_name : contactInfo.first_name,
                    contact_last_name : contactInfo.last_name,
                    contact_postCode : contactInfo.postCode,
                    contact_country : contactInfo.country,
                    contact_province : contactInfo.province,
                    contact_city : contactInfo.city,
                    contact_address : contactInfo.address,
                    contact_phone :  contactInfo.phone,
                  });
                }
              }
              this.checkoutForm.patchValue({
                //Referrals : this.user.isDistributor==1 ? (this.user.pTimes==0 ? this.user.pre_sponsor: this.user.Referrals) : '',
                Referrals :  this.user.Referrals? this.user.Referrals : '' ,
                Recommended : this.user.Recommended ? this.user.Recommended : (this.user.pTimes==0 ? this.user.pre_sponsor : this.user.Recommended),
                //name : this.user.first_name + " " + this.user.last_name,
                firstName : this.user.first_name,
                lastName : this.user.last_name,
                postCode : this.user.PostCode,
                country : this.user.Country,
                province : this.user.Province,
                city : this.user.City,
                Address : this.user.Address,
                //Email : this.user.email,
              });

              this.I_am_Distributor = (this.user.isDistributor && this.user.isDistributor==1? true : false);


              if(!this.user.isDistributor){
                this.checkoutForm.controls['Referrals'].disable();
                this.checkoutForm.controls['Recommended'].disable();
              }else{
                this.checkoutForm.controls['Referrals'].enable();
                this.checkoutForm.controls['Recommended'].enable();

                if(this.user.Referrals){
                  this.checkoutForm.controls['Referrals'].disable();
                }

                if(this.user.Recommended && this.user.Referrals){
                  this.checkoutForm.controls['Recommended'].disable();
                }
                
                if(this.user.Recommended && this.user.Referrals){
                  this.verified = true;
                }
              }
              if(this.user.Country && this.user.Province && this.tax_rates){
                this.cart_tax_rate = this.tax_rates.find(rate => {
                  return rate.state_name.toUpperCase() === this.user.Province.toUpperCase()
                })
                this.shipping_fee = this.cart_tax_rate && this.cart_tax_rate.shipping_fee ? this.cart_tax_rate.shipping_fee : 0;
                this.payby_currency = this.cart_tax_rate && this.cart_tax_rate.currency_code ? this.cart_tax_rate.currency_code : 'USD';
                this.collectPaymentInfo(this.cart_tax_rate,1);
                
                this.getCart();
              }
              this.cdref.detectChanges();
              this.setOnePlacer();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  openPage(pageId) {
    this.activeModal.close('Close click');
    this.router.navigate([pageId]);
  }

  setOnePlacer(show_resp:boolean=false){
    if ( this.user.pTimes==0 && this.user.pre_sponsor && this.user.isDistributor==1 && !(this.user.Referrals)) {
      this.evgService.getOnePlacerforNewuser({pre_sponsor : this.user.pre_sponsor}).subscribe( resp => {
        if (resp) {
          if (show_resp) {
            this.responseHandlerService.handleResponse(resp);
          }
          if ( resp.success) {
            //this.user.Referrals = resp.data[0].results.placer;
            this.checkoutForm.patchValue({
              Referrals : resp.data[0].results.placer,
            });
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
      });

    }

  }

/**
  distrib_click(){
    this.registerForm.patchValue({
      distributor_terms : false
    })
    const modalRef = this.ngDialog.open(DistributorTermsComponent, { windowClass: 'distributor-terms-modal' ,centered: true, size: 'md', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;

    modalRef.result.then((data) => {
    }, (reason) => {
      this.registerForm.patchValue({
        distributor_terms : true
      })
    });
  }
 * 
 */
  distrib_click(){
    const modalRef = this.ngDialog.open(DistributorTermsComponent, { windowClass: 'distributor-terms-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.distributor = this.user.first_name + ' ' + this.user.last_name;

    modalRef.result.then((data) => {
    }, (reason) => {
        this.evgService.updateUserDistributorFlag({isDistributor : 1}).subscribe( resp => {
          this.responseHandlerService.handleResponse(resp);
          this.shareinfoService.reloadUser();
          this.setOnePlacer();
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
    });
  }

  showNoticePassword(){
    const modalRef = this.ngDialog.open(AlertDialogComponent, { windowClass: 'register-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.message = this.responseHandlerService.translateMessage("WHATISCVVCD") ;
    modalRef.componentInstance.imagePass ='../../../../assets/images/icons/cvvnumber.png'

  }

  onSelected(i,event){
    this.cart_items[i].selected = this.cart_items[i].selected == 1 ? 0 : 1;
    this.evgService.modifyCart( this.cart_items[i])
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          if(resp.success){
            this.shareinfoService.refreshCart();
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  deleteItem(index){
    let item = this.cart_items[index];
    
    this.cart_items.splice(index, 1);
    this.getShippingFee(this.checkoutForm.get('PostCode').value, this.checkoutForm.get('Country').value);

    this.evgService.deleteCartByPID({pId : item.pId}).subscribe( resp => {
      if (resp) {
        this.shareinfoService.refreshCart();
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  addItem(index){
    
    this.cart_items[index].nums++;
    this.getShippingFee(this.checkoutForm.get('PostCode').value, this.checkoutForm.get('Country').value);
    this.evgService.modifyCart( this.cart_items[index])
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          if(resp.success){
            this.shareinfoService.refreshCart();
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
      //this.getShippingFee(this.checkoutForm.get('PostCode').value, this.checkoutForm.get('Country').value);
  }

  removeItem(index){
    if(this.cart_items[index].nums >= 2){
      this.cart_items[index].nums--;
      this.getShippingFee(this.checkoutForm.get('PostCode').value, this.checkoutForm.get('Country').value);
      this.evgService.modifyCart( this.cart_items[index])
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            if(resp.success){
              this.shareinfoService.refreshCart();
            }
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
  
    }else{
      /*
      this.evgService.deleteCartByPID({pId : this.cart_items[index].pId}).subscribe( resp => {
        if (resp) {
          this.shareinfoService.refreshCart();
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });*/
      this.deleteItem(index);
    }

    //this.getShippingFee(this.checkoutForm.get('PostCode').value, this.checkoutForm.get('Country').value);
  }


  clearCart(){
    this.evgService.clearCart({}).subscribe( resp => {
      if (resp) {
        //console.log("CART",resp);
        this.shipping_fee=0;
        this.cart_tax_rate=null;
        this.cart_total =0;
        this.payPal_amount=0;
        this.cart_subtotal=0;
        this.cart_discounts =0;
        this.common_discount =0;
        this.autoship_discount =0;
        this.cart_tax =0
        this.payby_currency="USD";
        this.shareinfoService.refreshCart();

      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });

  }

  getCountryList(){
    this.evgService.getCountryList({}).subscribe( resp => {
      if (resp) {
        this.countries = resp.data[0].results;
        this.contact_countries = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getProvinceList(country,for_contact=0)
  {
    this.evgService.getProvinceList({country : country}).subscribe( resp => {
      if (resp) {
        //console.log(resp);
        if (for_contact==0) {
          this.provinces = resp.data[0].results;
        } else {
          this.contact_provinces = resp.data[0].results;
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getUserShippingAddresses()
  {
    this.evgService.getUserShippingAddresses({}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              //console.log("getUserShippingAddresses",successResponse);
              let shippingAddresses =successResponse.data[0].results;
              let choice_Existing =0;
              if (shippingAddresses.length>0) {
                this.shippingAddresses = shippingAddresses.filter(item => item.as_billing==0);
                console.log('getUserShippingAddresses after filter shippingAddresses',this.shippingAddresses)
                if (shippingAddresses[0].as_billing ==0 ) {
                  choice_Existing=1;
                }
              }
              //this.shippingAddresses = successResponse.data[0].results;

              if(this.shippingAddresses.length <= 0){
                //this.preferredShipping = 1;
                this.preferredShippingChanged();
                this.checkoutForm.patchValue({
                  Tel : this.user.Tel,
                  Address : this.user.Address,
                  City : this.user.City,
                  Province : this.user.Province,
                  Country : this.user.Country,
                  PostCode : this.user.PostCode,
                  first_name : this.user.first_name,
                  last_name : this.user.last_name,
                });
              }else{

                //let lastShippingInfo = this.shippingAddresses.find()
                //this.preferredShipping = 0;
                this.selectedAddress = 0;
                if ( choice_Existing ==1) {
                   this.preferredShipping =0;
                }
                this.preferredShippingChanged((choice_Existing ==1? 1:0));

              }
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  deleteShippingAddress(id)
  {
    this.evgService.deleteShippingAddress({id : id}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            if (successResponse.success) {
              this.shippingAddresses = successResponse.data[0].results;
              if(this.shippingAddresses && this.shippingAddresses.length <= 0){
                this.preferredShipping = 1;
                this.preferredShippingChanged();
                this.checkoutForm.patchValue({
                  Tel : this.user.Tel,
                  Address : this.user.Address,
                  City : this.user.City,
                  Province : this.user.Province,
                  Country : this.user.Country,
                  PostCode : this.user.PostCode,
                  first_name: this.user.first_name,
                  last_name : this.user.last_name,
                });
              }else{
                this.preferredShipping = 0;
                this.selectedAddress = 0;
                this.preferredShippingChanged();
              }
            }
            this.responseHandlerService.handleResponse(successResponse);
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  // david added 
  getShippingFee(postCode :string, country :string)
  {
    if(postCode.length<4 || country.length<4) {
      return;
    }

    let set = new Set();
    if(this.isFreeShipping()) {
      this.checkoutForm.get('selectedShippingOption').clearValidators();
      this.evgService.getShippingFee(postCode, country, 0).subscribe( resp => {
        if (resp.success) {
            this.shippingOptions = [];
            this.cdref.markForCheck();
          } else {
            this.checkoutForm.get('contact_postCode').setErrors({'invalidPostCode':true});
            this.cdref.markForCheck();
          }
        },(errorResult) => {
        this.checkoutForm.get('contact_postCode').setErrors({'invalidPostCode':true});
        this.cdref.markForCheck();
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }else{
      this.cart_items.forEach(e=>{
          set.add(this.getParcelChar(e.pId));
      });
    }

   if(set.has(1)) {
      
      this.evgService.getShippingFee(postCode, country, 1).subscribe( resp => {
        if (resp.success) {
            let i = 0;
            let length = resp.data.length<3?resp.data.length:3;
            if(resp.data[0].hasOwnProperty("serviceName")) {
              let shippingOptions = resp.data.slice(0,length);
              /*
              let count = 0;
              this.cart_items.forEach( e=> {
                if(this.getParcelChar(e.pId)===1) {
                  count += e.nums;
                }
              })*/

              if(country.toLowerCase() != "canada" && country.toLowerCase() !='united states') {
                shippingOptions.forEach( e => { 
                  e.priceDetails.due = e.priceDetails.due*0.8;
                });
              }

              if(this.cart_items.length === 1 && this.cart_items.filter(e => e.pId == 4).length>0) {
                shippingOptions.forEach( e => { 
                  e.priceDetails.due = 35;
                });
              }
              
                
              this.shippingOptions = shippingOptions;
              this.shippingOptionsCache = this.shippingOptions;
              this.cdref.markForCheck();
            }
          } else {
            this.shippingOptions = [];
            this.checkoutForm.get('contact_postCode').setErrors({'invalidPostCode':true});
            this.cdref.markForCheck();
          }
        },(errorResult) => {
        this.shippingOptions = [];
        this.checkoutForm.get('contact_postCode').setErrors({'invalidPostCode':true});
        this.cdref.markForCheck();
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
   }else if(set.has(0)){

      this.evgService.getShippingFee(postCode, country, 0).subscribe( resp => {
              if (resp.success) {
                let i = 0;
                let length = resp.data.length<3?resp.data.length:3;
                if(resp.data[0].hasOwnProperty("serviceName")) {
                  let shippingOptions=resp.data.slice(0,length);
                  /*
                  let count = 0;
                  this.cart_items.forEach( e=> {
                    if(this.getParcelChar(e.pId)===0) {
                      count += e.nums;
                    }
                  });
                  shippingOptions.forEach( e => { 
                    e.priceDetails.due = e.priceDetails.due*count;
                  });
                  */
                  this.shippingOptions = shippingOptions;
                  this.shippingOptionsCache = this.shippingOptions;
                  this.cdref.markForCheck();
                }
              } else {
                this.shippingOptions = [];
                this.checkoutForm.get('contact_postCode').setErrors({'invalidPostCode':true});
                this.cdref.markForCheck();
              }
            },(errorResult) => {
            this.shippingOptions = [];
            this.cdref.markForCheck();
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
   }

   this.shipping_fee = 0;
   this.selectedShippingOption='';
   this.shippingOptions = [];
   this.checkoutForm.patchValue({selectedShippingOption:''});
   this.cdref.markForCheck();
   
  }

  getCart(init=0){
    this.shareinfoService.cart.pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.cart_items = successResponse;
              //console.log('getCart successResponse:', successResponse);
              this.cart_subtotal = 0;
              this.cart_tax = 0;
              this.cart_tax_1 = 0;
              this.cart_tax_2 = 0;
              this.cart_total = 0;
              this.cart_item_selected = 0;
              this.cart_discount_rate = 0;
              this.autoship_discount_rate=0;
              this.autoship_discount =0;
              this.cart_items.forEach(cart_item => {
                  if(cart_item.selected == 1){
                    this.cart_subtotal += (cart_item.unitPrice + (cart_item.apply_benefit==1 ? cart_item.benefit_value : 0)) * cart_item.nums;
                    this.cart_total += cart_item.amount;
                    this.cart_item_selected++;
                    if (cart_item.autoship ==1 && cart_item.termFlag ==1) {
                      this.autoship_discount += (cart_item.nums*cart_item.unitPrice)*cart_item.autoship_discount;
                    }
                    if (init && this.termsForAutoship && this.termsForAutoship.length>0) {
                      let obj:any= this.termsForAutoship.filter(d => d.pId == cart_item.pId);
                      if (obj) {
                        cart_item.termFlag = obj.termFlag;
                      }
                    }
                  }
              });
              this.getDiscountAmt();


            this.cart_items.forEach(cart_item => {
                if(cart_item.selected == 1){
                  this.cart_tax += this.calculateProductTax(cart_item);
                  this.cart_tax_1 += this.calculateProductTax1(cart_item);
                  this.cart_tax_2 += this.calculateProductTax2(cart_item);
                  
                }
            });

            this.cart_tax = this.cart_tax>0?this.cart_tax:0;
            this.cart_tax_1 = this.cart_tax_1>0?this.cart_tax_1:0;
            this.cart_tax_2 = this.cart_tax_2>0?this.cart_tax_2:0;

            this.cart_total = this.cart_subtotal - this.cart_discounts - this.common_discount - this.autoship_discount + this.cart_tax;
            this.cart_total = this.cart_total>0?this.cart_total:0;
            this.cart_total += this.shipping_fee;
            this.payPal_amount = Math.round(this.cart_total*100)/100;
            this.cdref.detectChanges();
              
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  calculateProductTaxold(cart_item){
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        return (cart_item.unitPrice * this.cart_tax_rate.tax_rate)  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 2 ){
        return (cart_item.unitPrice * (this.cart_tax_rate.tax_rate2 ? this.cart_tax_rate.tax_rate2 : this.cart_tax_rate.tax_rate))  * cart_item.nums * (1 - this.cart_discount_rate);
      }else if(cart_item.tax_type == 3 ){
        return this.cart_tax_rate.tax_rate2 ? (((cart_item.unitPrice * this.cart_tax_rate.tax_rate) + (cart_item.unitPrice * this.cart_tax_rate.tax_rate2))  * cart_item.nums * (1 - this.cart_discount_rate)) : ((cart_item.unitPrice * this.cart_tax_rate.tax_rate)  * cart_item.nums * (1 - this.cart_discount_rate))
      }else if(cart_item.tax_type == 4 ){
        return this.cart_tax_rate.tax_rate2 ? (((cart_item.gst_share_amt * this.cart_tax_rate.tax_rate) + (cart_item.pst_share_amt * this.cart_tax_rate.tax_rate2))  * cart_item.nums * (1 - this.cart_discount_rate)) : ((cart_item.gst_share_amt * this.cart_tax_rate.tax_rate)  * cart_item.nums * (1 - this.cart_discount_rate))
      }
    }
    return 0;
  }

  calculateProductTax(cart_item){
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 2 ){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 3 ){
        return  (cart_item.unitPrice * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate) + cart_item.unitPrice * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate))
      }else if(cart_item.tax_type == 4 ){
        return  (cart_item.gst_share_amt * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate) + cart_item.pst_share_amt * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate))
      }
    }
    return 0;
  }

  calculateProductTax1(cart_item){
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 2 ){
        return 0;
      }else if(cart_item.tax_type == 3 ){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 4 ){
        return cart_item.gst_share_amt * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate)
      }
    }
    return 0;
  }

  calculateProductTax2(cart_item){
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        return 0;
      }else if(cart_item.tax_type == 2 ){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 3 ){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 4 ){
        return cart_item.pst_share_amt * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate)
      }
    }
    return 0;
  }

  nextStep(){
    this.step++;
    if (this.step==2) {
      document.querySelector(".modal").scrollTo({top:0,behavior:'smooth'});
      this.step=3;
      this.setShippingInfo();

      if (true) {
        let validateData = this.collectValidationData();
        //console.log('nextStep validateData ',validateData);
        if (validateData) {
          if (validateData.payType==null) {
            validateData.payType=2;
          }
          this.evgService.validatePurchaseOnly(validateData).subscribe( resp => {
            if (resp && resp.success==true) {
              this.step=3;
            } else {
              this.step=1;
              this.responseHandlerService.handleResponse(resp);
            }
            console.log('nextStep validatePurchaseOnly resp: step: ',resp, this.step);
          },(errorResult) => {
            this.step--;
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          });
        } else {
          this.step--;        
        }
      }
    } else {
      
    }
  }

  skipStep(){
    this.step++;
    if (this.step==2) {
      this.step=3;
    }
  }

  prevStep(){
    this.step--;
    if (this.step<1) {
      this.step=1;
    }
    if (this.step==2) {
      this.step=1;
    }
    document.querySelector(".modal").scrollTo({top:0,behavior:'smooth'});
    //this.show_contact_info_isNG();
    this.findInvalidControls();
  }

  renderTaxName(cart_item)
  { 
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        return this.cart_tax_rate.tax_name + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate,'0.0-3') + ")";
      }else if(cart_item.tax_type == 2 ){
        return this.cart_tax_rate.tax_name2 ? (this.cart_tax_rate.tax_name2 + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate2,'0.0-3') + ")") : this.translate.translate.instant("NOTAX");
      }else if(cart_item.tax_type == 3 ){
        return this.cart_tax_rate.tax_name2 ? (this.cart_tax_rate.tax_name + ' + ' + this.cart_tax_rate.tax_name2  + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate + this.cart_tax_rate.tax_rate2,'0.0-3') + ")") : (this.cart_tax_rate.tax_name  + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate,'0.0-3') + "%)");
      }else if(cart_item.tax_type == 4 ){
        return this.cart_tax_rate.tax_name2 ? (this.cart_tax_rate.tax_name + ' + ' + this.cart_tax_rate.tax_name2  + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate + this.cart_tax_rate.tax_rate2,'0.0-3') + ")") : (this.cart_tax_rate.tax_name  + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate,'0.0-3') + "%)");
      }
    }
    //return this.translate.translate.instant('PROCEEDBILLING');
    return '';
  }

  getTaxRate(){
    this.shareinfoService.tax_rate.subscribe( resp => {
      if (resp) {
        this.cart_tax_rate = resp
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  collectValidationData(forcePass=0) {
    let hasErrors=0;
    let errors=[];
    let return_data:any=null;
    let hasError:boolean = false;
    if(this.checkoutForm.valid || (this.pay_type != 0) || forcePass==1){
      let hasError:boolean = false;

      if(this.cart_item_selected > 0){
            if (this.pay_type == 0) {
              hasError = !this.shareinfoService.validateExpiryMMYY(this.checkoutForm.get('expireDate').value);
              if (hasError == false) {
                hasErrors++;
                errors.push('Your credit card is expired!');
              }
            }
            if (hasErrors==0 || forcePass==1) {
              return_data = {
                  payType : this.pay_type,// this.checkoutForm.get('payType').value,
                  discount_rate : 0,// this.cart_discount_rate,
                  products :[],
                  subtotal : this.cart_subtotal,
                  discount_amount : (this.cart_discounts + this.common_discount + this.autoship_discount),
                  taxes : this.cart_tax,
                  amount : this.cart_total,
                  gstAmt  : this.cart_tax_1,
                  pstAmt : this.cart_tax_2,
                  shipping_fee : this.shipping_fee,
                  currency_code : this.payby_currency,
                  shippingId : -1,
                  referral : this.checkoutForm.get('Referrals').value ? this.checkoutForm.get('Referrals').value : null,
                  recommend : this.checkoutForm.get('Recommended').value ? this.checkoutForm.get('Recommended').value : null,
                  contact_info :{
                    MemberNo : this.user.id,
                    first_name : this.checkoutForm.get('contact_first_name').value,
                    last_name : this.checkoutForm.get('contact_last_name').value,
                    email : this.checkoutForm.get('contact_email').value,
                    phone : (this.checkoutForm.get('contact_phone').value).toString().replace(/\s+/g, '').replace(/-+/g, ''),
                    country : this.checkoutForm.get('contact_country').value,
                    province : this.checkoutForm.get('contact_province').value,
                    city : this.checkoutForm.get('contact_city').value,
                    address : this.checkoutForm.get('contact_address').value,
                    postCode : this.checkoutForm.get('contact_postCode').value,
                  },
                  use_billing_adr : this.sameasbillingadr? 1: 0
                }
      
                this.cart_items.forEach(cart_item => {
                    if(cart_item.selected == 1){
                      // data.subtotal += (cart_item.unitPrice) * cart_item.nums;
                      return_data.products.push({
                        pId : cart_item.pId,
                        nums : cart_item.nums,
                        price : cart_item.unitPrice,
                        termFlag: cart_item.termFlag,
                        apply_benefit: cart_item.apply_benefit,
                        benefit_name: cart_item.benefit_name,
                        benefit_value: cart_item.benefit_value,
                      });
                    }
                });
                //if(this.checkoutForm.get('payType').value == 0){
                if(this.pay_type == 0){
                    return_data["credit_card"] =  {
                    cardNo : (this.checkoutForm.get('cardNo').value).toString().replace(/\s+/g, ''),
                    expireDate : this.checkoutForm.get('expireDate').value,
                    cvCode : this.checkoutForm.get('cvCode').value,
                    firstName : this.checkoutForm.get('firstName').value,
                    lastName : this.checkoutForm.get('lastName').value,
                    postCode : this.checkoutForm.get('contact_postCode').value,
                    address : this.checkoutForm.get('contact_address').value,
                    email :  this.checkoutForm.get('contact_email').value,
                  };
                  console.log('call purchase(data) data:',return_data);
              }
            }
      } else {
        hasErrors++;
        errors.push('No products selected!');
      }
    } else {
      hasErrors++;
      errors.push('The data has errors!');
    }
    if (hasErrors>0) {
      alert("There are errors,"+errors[0]);
    }
    return return_data;
  }

  finishSteps()
  {
    if(this.checkoutForm.valid){
      let hasError:boolean = false;

      if(this.cart_item_selected > 0){
          if (this.pay_type ==0){ //this.checkoutForm.get('payType').value == 0) {
            hasError = !this.shareinfoService.validateExpiryMMYY(this.checkoutForm.get('expireDate').value);
          }
          if (hasError == false) {
    
            const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
            modalRef.componentInstance.translate = this.translate;
            modalRef.componentInstance.message = this.translate.translate.instant('CONTPURCH');
            modalRef.result.then((result) => {
              if(result){
                var data = {
                  payType : this.pay_type,// this.checkoutForm.get('payType').value,
                  discount_rate : 0,// this.cart_discount_rate,
                  products :[],
                  subtotal : this.cart_subtotal,
                  discount_amount : (this.cart_discounts + this.common_discount + this.autoship_discount),
                  taxes : this.cart_tax,
                  amount : this.cart_total,
                  gstAmt  : this.cart_tax_1,
                  pstAmt : this.cart_tax_2,
                  shipping_fee : this.shipping_fee,
                  currency_code : this.payby_currency,
                  shippingId : -1,
                  referral : this.checkoutForm.get('Referrals').value ? this.checkoutForm.get('Referrals').value : null,
                  recommend : this.checkoutForm.get('Recommended').value ? this.checkoutForm.get('Recommended').value : null,
                  contact_info :{
                    MemberNo : this.user.id,
                    first_name : this.checkoutForm.get('contact_first_name').value,
                    last_name : this.checkoutForm.get('contact_last_name').value,
                    email : this.checkoutForm.get('contact_email').value,
                    phone : (this.checkoutForm.get('contact_phone').value).toString().replace(/\s+/g, '').replace(/-+/g, ''),
                    country : this.checkoutForm.get('contact_country').value,
                    province : this.checkoutForm.get('contact_province').value,
                    city : this.checkoutForm.get('contact_city').value,
                    address : this.checkoutForm.get('contact_address').value,
                    postCode : this.checkoutForm.get('contact_postCode').value,
                  },
                  use_billing_adr : this.sameasbillingadr? 1: 0
                }
      
                this.cart_items.forEach(cart_item => {
                    if(cart_item.selected == 1){
                      // data.subtotal += (cart_item.unitPrice) * cart_item.nums;
                      data.products.push({
                        pId : cart_item.pId,
                        nums : cart_item.nums,
                        price : cart_item.unitPrice,
                        termFlag: cart_item.termFlag,
                        apply_benefit: cart_item.apply_benefit,
                        benefit_name: cart_item.benefit_name,
                        benefit_value: cart_item.benefit_value,
                      });
                    }
                });
          
                if(this.preferredShipping == 1 || this.preferredShipping == 2){
                  let phone = (this.sameasbillingadr ? this.checkoutForm.get('contact_phone').value : this.checkoutForm.get('Tel').value).toString().replace(/\s+/g, '').replace(/-+/g, '');
                  let shippingData = {
                    first_name: this.sameasbillingadr ? this.checkoutForm.get('contact_first_name').value : this.checkoutForm.get('first_name').value,
                    last_name: this.sameasbillingadr ? this.checkoutForm.get('contact_last_name').value : this.checkoutForm.get('last_name').value,
                    Tel : phone,
                    Address : this.sameasbillingadr ? this.checkoutForm.get('contact_address').value : this.checkoutForm.get('Address').value,
                    City : this.sameasbillingadr ? this.checkoutForm.get('contact_city').value : this.checkoutForm.get('City').value,
                    Province : this.sameasbillingadr ? this.checkoutForm.get('contact_province').value : this.checkoutForm.get('Province').value,
                    Country : this.sameasbillingadr ? this.checkoutForm.get('contact_country').value : this.checkoutForm.get('Country').value,
                    PostCode : this.sameasbillingadr ? this.checkoutForm.get('contact_postCode').value : this.checkoutForm.get('PostCode').value,
                    Email : this.checkoutForm.get('contact_email').value,
                    as_billing : this.sameasbillingadr ? 1 :0,
                  }
                  this.evgService.postShippingAddress(shippingData).subscribe( resp => {
                    if (resp) {
                      if(resp.success){
                        data.shippingId = resp.data[0].shippingId;
                        this.purchase(data);
                      } else {
                        this.responseHandlerService.handleResponse(resp);
                      }
                    }
                  },(errorResult) => {
                    console.log("System Error", errorResult);
                    this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
                  });
                }else if (this.preferredShipping == 0) {
                  if(this.selectedAddress >= 0){
                    data.shippingId = this.shippingAddresses[this.selectedAddress].id;
                    this.purchase(data);
                  } else {
                    let errmsg = { success : false, data : [{error : "No shipping address selected!"}],  message : "NOSHIPADR" };
                    this.responseHandlerService.handleResponse(errmsg);
                  }
                } else {
                  this.purchase(data);
                }
              }
            });
        } else {
          let errmsg = { success : false, data : [{error : "expired date"}],  message : "UNUSECARD" };
          this.responseHandlerService.handleResponse(errmsg);
        }
      }else{
        this.responseHandlerService.handleCustomResponse("Please select/add items","warning");
      }
    }
  }

  newFinishStep(payPalPost,forcePass=1){
    this.shareinfoService.setCreditProcess(true);
    let postData = this.collectValidationData(forcePass);
    postData["payPalPost"] = payPalPost;
    if(this.preferredShipping == 1 || this.preferredShipping == 2){
      let shippingData = {
        first_name : this.preferredShipping == 1 && this.checkoutForm.get('first_name').value ?  this.checkoutForm.get('first_name').value : this.checkoutForm.get('contact_first_name').value,
        last_name : this.preferredShipping == 1 && this.checkoutForm.get('last_name').value ?  this.checkoutForm.get('last_name').value : this.checkoutForm.get('contact_last_name').value,
        Tel : this.sameasbillingadr ? this.checkoutForm.get('contact_phone').value : this.checkoutForm.get('Tel').value,
        Address : this.sameasbillingadr ? this.checkoutForm.get('contact_address').value : this.checkoutForm.get('Address').value,
        City : this.sameasbillingadr ? this.checkoutForm.get('contact_city').value : this.checkoutForm.get('City').value,
        Province : this.sameasbillingadr ? this.checkoutForm.get('contact_province').value : this.checkoutForm.get('Province').value,
        Country : this.sameasbillingadr ? this.checkoutForm.get('contact_country').value : this.checkoutForm.get('Country').value,
        PostCode : this.sameasbillingadr ? this.checkoutForm.get('contact_postCode').value : this.checkoutForm.get('PostCode').value,
        Email : this.checkoutForm.get('contact_email').value,
        as_billing : this.sameasbillingadr ? 1 :0,
      }
      this.evgService.postShippingAddress(shippingData).subscribe( resp => {
        if (resp) {
          if(resp.success){
            postData.shippingId = resp.data[0].shippingId;
            this.purchase(postData);
          } else {
            this.responseHandlerService.handleResponse(resp);
          }
        }
      },(errorResult) => {
        this.shareinfoService.setCreditProcess(false);
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }else if (this.preferredShipping == 0) {
      if(this.selectedAddress >= 0){
        postData.shippingId = this.shippingAddresses[this.selectedAddress].id;
        this.purchase(postData);
      } else {
        let errmsg = { success : false, data : [{error : "No shipping address selected!"}],  message : "NOSHIPADR" };
        this.responseHandlerService.handleResponse(errmsg);
      }
    } else {
      this.purchase(postData);
    }
  }

  purchase(data)
  {
    if(this.pay_type==0) {//this.checkoutForm.get('payType').value == 0){
      if (data.credit_card == undefined || !data.credit_card) {
        data.credit_card =  {
          cardNo : (this.checkoutForm.get('cardNo').value).toString().replace(/\s+/g, ''),
          expireDate : this.checkoutForm.get('expireDate').value,
          cvCode : this.checkoutForm.get('cvCode').value,
          firstName : this.checkoutForm.get('firstName').value,
          lastName : this.checkoutForm.get('lastName').value,
          postCode : this.checkoutForm.get('contact_postCode').value,
          address : this.checkoutForm.get('contact_address').value,
          email :  this.checkoutForm.get('contact_email').value,
        };
      }
      //console.log('call purchase(data) data:',data);
      this.shareinfoService.setCreditProcess(true);
      this.evgService.doPurchase(data).subscribe( resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          this.shareinfoService.setCreditProcess(false);
          if(resp.success){
            this.clearCart();
            this.shareinfoService.reloadUser();
            this.activeModal.close('Close click');
          }
        }
      },(errorResult) => {
        this.shareinfoService.setCreditProcess(false);
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }else{
      if((this.user.totalCash >= this.cart_total && this.cart_total>=0) || (this.pay_type>=2 )){
        this.shareinfoService.setCreditProcess(true);
        this.evgService.doPurchase(data).subscribe( resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            this.shareinfoService.setCreditProcess(false);
            if(resp.success){
              this.clearCart();
              this.shareinfoService.reloadUser();
              this.activeModal.close('Close click');
            }
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.shareinfoService.setCreditProcess(false);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
      }else{
        this.responseHandlerService.handleCustomResponse("Not enough funds!","warning");
      }
    }
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  verify()
  {
    
    if(this.checkoutForm.get('Referrals').value && this.checkoutForm.get('Recommended').value){

        this.evgService.verifyUser({userId : this.checkoutForm.get('Recommended').value}).subscribe(resp => {
          if(resp){
            if(resp.data[0].results.length > 0){
              var sponsor = resp.data[0].results[0];
              this.evgService.verifyUser({userId : this.checkoutForm.get('Referrals').value}).subscribe(resp => {
                if(resp){
                  if(resp.data[0].results.length > 0){
                    var placer = resp.data[0].results[0];
                    const modalRef = this.ngDialog.open(VerifyDialogComponent, { windowClass: 'verify-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
                      modalRef.componentInstance.translate = this.translate;
                      modalRef.componentInstance.message = "Sponsor : " + this.redactName(sponsor);
                      modalRef.componentInstance.message2 = "Placer : " + this.redactName(placer);
                      modalRef.result.then((result) => {
                        if(result){
                           this.verified = true;
                           this.cdref.detectChanges();
                        }
                      });
                    this.cdref.detectChanges();
                  }else{
                    const modalRef = this.ngDialog.open(AlertDialogComponent, { windowClass: 'alert-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
                    modalRef.componentInstance.translate = this.translate;
                    modalRef.componentInstance.message = "Sponsor and/or Placer not found.";
                  }
                  
                }
              });
              this.cdref.detectChanges();
            }else{
              const modalRef = this.ngDialog.open(AlertDialogComponent, { windowClass: 'alert-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
              modalRef.componentInstance.translate = this.translate;
              modalRef.componentInstance.message = "Sponsor and/or Placer not found.";
            }
            
          }
        });
    }
  }

  redactName(user){
    if(user){
      var modifiedFirstName = '';
      var modifiedLastName = '';
      if(user.first_name.length > 2){
        modifiedFirstName = user.first_name.substr(0,1);
        for (let index = 0; index < (user.first_name.length - 2); index++) {
          modifiedFirstName += '*';
        }
        modifiedFirstName += user.first_name.substr(user.first_name.length - 1);
      }else{
        modifiedFirstName = user.first_name.last_name;
      }

     if(user.last_name.length > 2){
          modifiedLastName = user.last_name.substr(0,1);
          for (let index = 0; index < (user.last_name.length - 2); index++) {
            modifiedLastName += '*';
          }
          modifiedLastName += user.last_name.substr(user.last_name.length - 1);
      }else{
        modifiedLastName = user.last_name;
      }
     return modifiedFirstName + ' ' + modifiedLastName;
    }
  }


  getTermAutoship(){
    this.evgService.getMyAutoshipProducts({}).subscribe( resp => { //getTermAutoship
      if (resp) {
        //console.log("getMyTermAutoship resp", resp);
        this.termsForAutoship = resp;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  changeTermFlag(selValue,index) {

    //console.log("changeTermFlag selValue,index:", selValue,index);
    //this.cart_items[index].termFlag = selValue;
  }

  changeTermFlagEvent(event,index) {

    //console.log("changeTermFlag selValue,index:", selValue,index);
    //this.cart_items[index].termFlag = selValue;
    this.cart_items[index].termFlag = (event.checked===true) ? 1:0;
    let data ={AwardId:"autoship", AwardValue:this.cart_items[index].pId};
    this.getCart();
    if (event.checked===true) {
      this.evgService.getAwardOptionsMessage(data).subscribe( resp => {
        if (resp) {
          //this.responseHandlerService.handleResponse(resp);
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  copyBillingAddr(event){

    //console.log("copyBillingAddr event:", event);
    /*
    this.checkoutForm.patchValue({
      Address : this.user.Address,
      City : this.user.City,
      Province : this.user.Province,
      Country : this.user.Country,
      PostCode : this.user.PostCode,
    });
    */
  }

  applyBenefit_click(event,index){
    //this.cart_items[index].apply_benefit = (event.target.checked ? 1:0);
    this.cart_items[index].apply_benefit = (event.checked === true)? 1:0;
    this.getCart();
    
    if (event.checked === true) {
      let data ={AwardId:"firstbuy", AwardValue:''}
      this.evgService.getAwardOptionsMessage(data).subscribe( resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
    
  }

  collectPaymentInfo(tax_rate,doReset=0){
    //this.collectPaymentInfo(this.cart_tax_rate,1);

  }

  setShippingInfo(){
    this.shippingInfo=
    {
      MemberNo : this.user.id,
      first_name : this.checkoutForm.get('contact_first_name').value,
      last_name : this.checkoutForm.get('contact_last_name').value,
      email : this.checkoutForm.get('contact_email').value,
      phone : this.checkoutForm.get('contact_phone').value,
      country : this.cart_tax_rate? this.cart_tax_rate.country_code : this.checkoutForm.get('contact_country').value,
      province : this.cart_tax_rate? this.cart_tax_rate.state_code : this.checkoutForm.get('contact_province').value,
      city : this.checkoutForm.get('contact_city').value,
      address : this.checkoutForm.get('contact_address').value,
      postCode : this.checkoutForm.get('contact_postCode').value,
    };

    //console.log('setShippingInfo : ',this.shippingInfo);

  }
  
  getPaypalProcess(ev_value){

    //console.log('getPaypalProcess event:',JSON.parse(ev_value));
    let resp =JSON.parse(ev_value);
    //console.log('getPaypalProcess event:',resp);
    if (resp.status=='success') {
      this.newFinishStep(resp.info);
    } else if (resp.status=='OnError') {
      alert("error:"+ JSON.stringify(resp.error));
    } else if ( resp.status =='onCancel') {
      alert("onCancel:"+ JSON.stringify(resp.error));
    }
  }

  public findInvalidControls() {
      const invalid = [];
      const controls = this.checkoutForm.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
              invalid.push(name);
          }
      }
      console.log("findInvalidControls invalid:",invalid);
      return invalid;
  }

  get checkCanSubmit(){
    return (this.checkoutForm.valid && (this.pay_type==0 || (this.pay_type==1 && this.user.totalCash>= this.cart_total && this.cart_total>=0)))
  }


  public initializeShippingFee() {

  }
 
}
