import { ChangeDetectorRef, Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from 'src/app/modules/login/login.component';
import { RegisterComponent } from 'src/app/modules/register/register.component';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import {  filter, takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { User } from 'src/app/modules/common/User';
import { BehaviorSubject, Subject } from 'rxjs';
import { EWalletLoginComponent } from 'src/app/modules/ewallet-login/ewallet-login.component';
import { RECAPTCHA_LANGUAGE } from "ng-recaptcha";
import { Message } from 'src/app/modules/common/Message';
import { CartItem } from 'src/app/modules/common/CartItem';
import { ReceiveCoinsComponent } from 'src/app/modules/receive-coins/receive-coins.component';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { CartComponent } from 'src/app/modules/cart/cart.component';
import { DownloadCentreComponent } from '../../pages/download-centre/download-centre.component';
import { CartViewComponent } from 'src/app/modules/cart-view/cart-view.component';
import { EventService } from 'src/app/services/event.service';
import { AlertDialogComponent } from 'src/app/modules/alert-dialog/alert-dialog.component';


//import {OnlinePaymentComponent } from 'src/app/modules/online-payment/online-payment.component';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() isByteCard : boolean=false;

  public isMobileMenuOpen:boolean  = false;
  public activeRoute = "";
  private _unsubscribe$ = new Subject<any>();
  public user : User = new User();
  public ewallet : User = new User();
  public isLoggedIn = false;
  public isEwalletLoggedIn = false;
  public currentRoute = '';
  public messages : Message[] = [];
  public rankName : string = 'Member';
  public mode : string = "user";
  public isChecked : boolean = false;
  public cart_items : CartItem[] = [];
  public cart_quantity : number = 0;
  private scrollPosition = 0;
  public data = [];
  public isPersonal = false;
  public invoiceAccessToken=null;
  public page_parameters=null;
  public closed_times:number=0;

  @HostListener('window:scroll', ['$event']) 
  doSomething(event) {
    this.scrollPosition = window.pageYOffset;
    let nav = document.getElementById('main-nav');
    if (this.scrollPosition >= 700 ) {
      nav.classList.add('nav-colored')
      nav.classList.remove('nav-transparent')
    } 
    else {
      nav.classList.remove('nav-colored')
      nav.classList.add('nav-transparent')
    }
  }

  constructor( 
    public translate : TranslateConfigService, 
    private router: Router,
    private evgService : EvgApiService,
    private ngDialog : NgbModal,
    private responseHandlerService : ResponseHandlerService,
    public shareinfoService : ShareinfoService,
    public cdref : ChangeDetectorRef,
    public sharedUtilService : SharedUtilService,
    public route: ActivatedRoute,
    public eventService: EventService
  ){ 
    // my_value ='en';
    //console.log('HeaderComponent isByteCard',this.isByteCard);
    this.checkDevice();
    let guestString = localStorage.getItem('guest');
    if (guestString) {
      this.page_parameters = guestString.toString().split(',');
    }

  }

  checkDevice(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      this.isMobileMenuOpen=true;
      //document.write("mobile device");
    }else{
      // false for not mobile device
      //document.write("not mobile device");
      /*
      if (window.innerWidth <= 869 ) {
        this.isMobileMenuOpen = true;
      }
      */
      //console.log('window.innerWidth:',window.innerWidth);
    }
  }

  ngOnInit(): void {

    // this.mode = localStorage.mode ? localStorage.mode : 'user';
    // this.shareinfoService.setMode(this.mode);
    // if(localStorage.mode){
    //   this.mode = localStorage.mode;
    //   if(this.mode == 'user') this.isChecked = false;
    //   else this.isChecked = true;
    // }

    this.shareinfoService.user.pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
              this.isLoggedIn = successResponse ? true : false;

          var userType = this.sharedUtilService.checkUserPermissions(this.user.id);
              if(this.isLoggedIn && userType == 'user'){
                //this.getMyRankName();
                this.rankName = this.shareinfoService.getULevelName(this.user.achievedRankId);
                this.shareinfoService.setRankName(this.rankName);
              }
              this.route.queryParams
                .subscribe(params => {
                  //console.log('in header params :',params);
                  if(params.page){
                    this.isPersonal = true;
                    this.data = this.sharedUtilService.decryptData(String(params.page).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')).split(',');
                    this.page_parameters= this.data;
                    if(this.user.id){
                      //console.log('in header data :',this.data);
                      if(this.data[0] != this.user.id){
                        localStorage.setItem(this.user.id.toString(),this.sharedUtilService.decryptData(String(params.page).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')));
                      }else{
                        this.data = [];
                      }
                    }else{
                      //console.log("GUEST");
                      localStorage.setItem('guest',this.sharedUtilService.decryptData(String(params.page).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')));
                    }
                    this.getSponsorInfo();
                  } 
                }
              );

              if(this.user.id){
                if(localStorage.getItem(this.user.id.toString())){
                  this.data = localStorage.getItem(this.user.id.toString()).toString().split(',');
                }else if(localStorage.getItem('guest')){
                  this.data = localStorage.getItem('guest').toString().split(',');
                }
              }else{
                  this.getSponsorInfo();
                  //console.log("localStorage.getItem('guest') this.data", this.data);
              }
              
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );

    this.shareinfoService.currentRoute.pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.currentRoute = successResponse;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );

    this.shareinfoService.mode.pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.mode = successResponse;
            this.cdref.detectChanges();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );


    this.shareinfoService.ewallet.pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.ewallet = successResponse ? successResponse : new User();
              this.isEwalletLoggedIn = successResponse ? true : false;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );

    this.getNewMessages();

    
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
    .subscribe(event => {
        this.activeRoute = event['url'];
    });

    this.getCart();

    this.eventService.on<any>().subscribe((data) => {
      if(data) {
        this.cart_items = data.cart_items;
        this.cart_quantity = this.cart_items.reduce((nums, item) => nums + item.nums, 0);
        //localStorage.setItem("myByteCart",JSON.stringify(this.cart_items));

        this.cdref.markForCheck();
      }
      
    });

  }

  redact(word){;
    return word;
    let replacement = '****';
    let result = '';
    if(word.length > 4){
      result = word.substring(0, word.length - 4) + replacement;
    }else{
      result = replacement;
    }
    return result;
  }

  showCartView(){
    if (this.cart_quantity >0 ) {
      const modalRef = this.ngDialog.open(CartViewComponent, { windowClass: 'activate-account-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.cart_items = this.cart_items;
      modalRef.result.then((result) => {
        this.cart_quantity = this.cart_items.reduce((nums, item) => nums + item.nums, 0);
        if(result){
        }
      });
      this.cdref.detectChanges();
    }
  }

  showCart()
  {
    //modalClass: 'modal-dialog-scrollable',
    const modalRef = this.ngDialog.open(CartComponent, {  windowClass: 'cart-modal' ,centered: true, size: 'xl', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
  }

  getCart(){
    var userType = this.sharedUtilService.checkUserPermissions(this.user.id);
    if(userType == 'user'){
      this.shareinfoService.refreshCart();
      this.shareinfoService.cart.pipe( takeUntil(this._unsubscribe$) )
          .subscribe(
            (successResponse: any) => {
              this.cart_items = successResponse;
              this.cart_quantity = 0;
              this.cart_items.forEach(item => {
                this.cart_quantity += item.nums;
              });
                
            },
            (errorResult) => {
              console.log("System Error", errorResult);
              this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
            }
      );
    }
  }

  ngAfterViewInit()
  {
    var s=document.createElement("script");
    s.type="text/javascript";
    s.innerHTML="console.log('done');"; //inline script
    // s.src="path/test.js"; //external script
    /*
    setTimeout(()=>{
      if (this.invoiceAccessToken) {
        const modalRef = this.ngDialog.open(OnlinePaymentComponent, { windowClass: 'cart-modal' ,centered: true, size: 'gl', keyboard: false, backdrop: "static" });
        modalRef.componentInstance.translate = this.translate;
      }
    },10);
*/
  }

  switchLang(lang: string) {
    this.translate.switchLang(lang);
    
  }

  getNewMessages(){
    this.shareinfoService.messages.pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.messages = successResponse;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );

  }

  getMyRankName(){
    this.evgService.getMyRankName({}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            if(successResponse.success){
              this.rankName = successResponse.data[0].results;
              this.shareinfoService.setRankName(this.rankName);
            }
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  
  viewMessage(index){
    if(this.messages[index].msg_type == 2){
      const modalRef = this.ngDialog.open(ReceiveCoinsComponent, { windowClass: 'receive-coins-login-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.message = this.messages[index];
    }else{
      this.evgService.clearReadFlag({id : this.messages[index].id, msg_type : this.messages[index].msg_type}).pipe( takeUntil(this._unsubscribe$) )
          .subscribe(
            (successResponse: any) => {
                if(successResponse.success){
                  this.router.navigate(["backmain/company-messages/" + this.messages[index].id]);
                }
                
            },
            (errorResult) => {
              console.log("System Error", errorResult);
              this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
            }
      );
    }
    
    this.getNewMessages();

  }

  openPage(pId) {

    if (pId === "login") {
      const modalRef = this.ngDialog.open(LoginComponent, { windowClass: 'login-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
    } else if (pId === "register") {
      const modalRef = this.ngDialog.open(RegisterComponent, { windowClass: 'register-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
    } else if (pId === "ewallet-login") {
      
      if(!this.shareinfoService.IsEwalletLoggedIn()){
        const modalRef = this.ngDialog.open(EWalletLoginComponent, { windowClass: 'ewallet-login-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
        modalRef.componentInstance.translate = this.translate;
        //console.log("ewallet-login");
      }
    } else if (pId === "download-centre")  {
      const modalRef = this.ngDialog.open(DownloadCentreComponent, { windowClass: 'ewallet-login-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      //console.log("ewallet-login");

    } else {
      this.router.navigate([pId]);
    }
  }


  logout() {
  
    this.evgService.signOut().subscribe(resp => {
      if(resp){
        this.shareinfoService.logout();
        //console.log("SIGNOUT",this.shareinfoService.isLoggedIn());
        this.responseHandlerService.handleResponse(resp);
        this.cdref.detectChanges();
        localStorage.setItem(this.shareinfoService.tokenStoreKey, null);
        this.data = [];
        if(localStorage.getItem('guest')){
          this.data = localStorage.getItem('guest').toString().split(',');
          //console.log("WQD",this.data);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
    
    this.router.navigate([this.shareinfoService.mainHomeId]);
  }

  eWalletLogout(){
    this.shareinfoService.eWalletLogout();
    this.responseHandlerService.handleCustomResponse("Ewallet Logged Out Successfully", "success");
    this.router.navigate(['backmain']);
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  showOption(inData,inKey) {
    let mesg:string='';
    if (inData) {
      mesg = this.translate.translate.instant(inKey);
    }
    //console.log('showOption mesg:',mesg);
    return mesg;

  }

  getSponsorInfo(){
    //console.log("getSponsorInfo Sponsor this.page_parameters:",this.page_parameters);
    if (this.page_parameters && this.page_parameters.length>0 && this.closed_times==0) {
      this.evgService.getSponsorInfo({ sponsorId : this.page_parameters[0]}).subscribe(resp => {
        if(resp && resp.success){
          let sponsor = resp.data[0].results;
          if (sponsor.isLocked) {
            //console.log("getSponsorInfo Sponsor isLocked!");
            this.cdref.detectChanges();
            localStorage.setItem(this.shareinfoService.tokenStoreKey, null);
            localStorage.setItem('guest', null);
            this.closed_times++;
            const modalRef = this.ngDialog.open(AlertDialogComponent, { windowClass: 'register-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
            modalRef.componentInstance.translate = this.translate;
            modalRef.componentInstance.message = this.responseHandlerService.translateMessage("LINKEXPIRED") ;
            modalRef.componentInstance.imagePass = null;//'../../../../assets/images/icons/cvvnumber.png'
        
            modalRef.result.then((result) => {
              window.close(); 
              //navigator['app'].exitApp(); // exit the app
              if(result){
              }
            });
      
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
    //this.router.navigate([this.shareinfoService.mainHomeId]);
  }

}
