<div class="bonus-overview-container">
    
  <div class="row gutters-sm">
    <div class="col-md-12">
        <div class="card mb-5">
          <div class="card-body">
              
            <h3 class="title mb-2">{{'RANKQUALINFO' | translate}}</h3>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                      <tr>
                        <!-- <th scope="col">#</th> -->
                        <th scope="col">{{'RANK' | translate}}</th>
                        <th scope="col">{{'STATUS' | translate}}</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <!-- <th scope="row">{{ transaction.id }}</th> -->
                        <!--<td>{{ (((user.uLevel > 0) || (user.uLevel == 0 && downlines.length > 0)) ) ? 'YES' : 'NO'   }}</td>-->
                        <td>{{ 'DEPTREQFULFILLED' | translate }}</td>
                        <td>{{ (((user.uLevel > 0) || (user.uLevel == 0 && downlines.length > 0)) && myRankInfo.qualifiedRank==1 ) ? 'YES' : 'NO'   }}</td>
                      </tr>
                      <tr>
                        <td>{{ 'AISACTIVE' | translate }}</td>
                        <td>{{ myRankInfo.isActive ? 'YES' : 'NO'   }}</td>
                      </tr>
                      <tr>
                        <td>{{ 'ACHIVEDRANK' | translate }}</td>
                        <td>{{ shareinfoService.getRankKey(myRankInfo.achievedRankId) | translate }}</td>
                      </tr>
                      <tr>
                        <td>{{ 'PAYINGRANK' | translate }}</td>
                        <td>{{ shareinfoService.getRankKey(myRankInfo.uLevel) | translate }}</td>
                      </tr>
                      <!--
                      <tr>
                        <td>{{ 'MONTHLYFEESPAID' | translate }}</td>
                        <td>{{ isMonthlyFeePaid ? 'YES' : 'NO' }}</td>
                      </tr>
                    -->
                    </tbody>
                  </table>
            </div>
          </div>
        </div>
    </div>
    
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title mb-2">{{'BONUSOVERVIEW' | translate}}</h3>
              <div class="table-responsive">
                  <table class="table table-bordered" matSort (matSortChange)="sortData($event)">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th mat-sort-header="dealDate" scope="col">{{'DATELABEL' | translate}}</th>
                          <th mat-sort-header="MemberNo" scope="col">{{'MEMBERID' | translate}}</th>
                          <!-- <th mat-sort-header="MemberNo" scope="col">{{'BONUSRECEIVER' | translate}}</th> -->
                          <th mat-sort-header="dealName" scope="col">{{'BONUSTYPE' | translate}}</th>
                          <th mat-sort-header="levels" scope="col">{{'LEVELS' | translate}}</th>
                          <th mat-sort-header="payingrank" scope="col">{{'PAYINGRANK' | translate}}</th>
                          <th mat-sort-header="amount" scope="col">{{'AMOUNTUSD' | translate}}</th>
                          <th mat-sort-header="inPending" scope="col">{{'PENDINGLABEL' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="!isLoading">
                          <tr *ngFor="let bonus of sortedBonuses; index as i;">
                            <!-- <th scope="row">{{ transaction.id }}</th> -->
                            <td>{{ bonus.dealDate }}</td>
                            <td>{{ bonus.MemberNo }}</td>
                            <!-- <td>{{ bonus.MemberNo }}</td> -->
                            <td>{{ bonus.dealName }} {{ bonus.amount < 0 ? ('(' + ('BONUSREDUCED' | translate) +')') : ''}}</td>
                            <td >{{ bonus.MsgInfo ? bonus.MsgInfo : bonus.levels }}</td>
                            <td >{{ shareinfoService.getRankKey(bonus.uLevel) | translate }}</td>
                            <td>{{ bonus.amount | currency:'' }}</td>
                            <td>{{ bonus.inPending ?  "YES" : "NO"}}</td>
                          </tr>
                          <tr>
                              <th colspan="4" class="text-right">{{'TOTALBONUS' | translate}}</th>
                              <th colspan="2">{{ totalBonus | currency:'' }}</th>
                          </tr>
                          <tr>
                              <th colspan="4" class="text-right">{{'TOTALINPENDINGBONUS' | translate}}</th>
                              <th colspan="2">{{ totalInPendingBonus | currency:''}}</th>
                          </tr>
                          <tr>
                            <th colspan="4" class="text-right">{{'BONUSAVAIL' | translate}}</th>
                            <th colspan="2">{{ bonusAvailable | currency:'' }}</th>
                          </tr>
                          <tr>
                              <th colspan="4" class="text-right">{{'BONUSAVAILPERCENT' | translate}}</th>
                              <th colspan="2"> {{ bonusAvailablePercentage ? (bonusAvailablePercentage/100 | percent:'1.2') : (0 | percent:'1.2') }}</th>
                          </tr>
                          <tr>
                            <th colspan="4" class="text-right">{{'BONUSCOOLING' | translate}}</th>
                            <th colspan="2">{{ bonusInCooling | currency:''}}</th>
                          </tr>
                          <tr>
                              <th colspan="4" class="text-right">{{'BONUSCOOLINGPERCENT' | translate}}</th>
                              <th colspan="2"> {{ bonusInCoolingPercentage ? (bonusInCoolingPercentage/100 | percent:'1.2') : (0 | percent:'1.2')}}</th>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="isLoading">
                          <tr>
                            <th colspan="6" class="text-center m-2">
                              <div class="spinner-border text-success" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </th>
                            
                          </tr>
                          
                        </ng-container>
                        
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
      </div>
      
    </div>
</div>