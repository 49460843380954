import { Injectable } from '@angular/core';
import { User } from 'src/app/modules/common/User';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { local } from 'd3';
import { EvgApiService } from './evg-api.service';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { CartItem } from '../modules/common/CartItem';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
//import { AlertBoxComponent } from '../dialog/alert-box/alert-box.component';

@Injectable({
  providedIn: 'root'
})
export class ShareinfoService {
  private _user$ = new BehaviorSubject<User>(null);
  private _tax_rate$ = new BehaviorSubject<any>(null);
  private _messages$ = new BehaviorSubject<Message[]>([]);
  private _ewallet$ = new BehaviorSubject<User>(null);
  private _cart_items$ = new BehaviorSubject<CartItem[]>([]);
  private _admins$ = new BehaviorSubject<any[]>([]);
  private _accounting$ = new BehaviorSubject<any[]>([]);
  private _supports$ = new BehaviorSubject<any[]>([]);
  private _mode$ = new BehaviorSubject<String>('user');
  private _rankName$ = new BehaviorSubject<String>('');
  private _isPrinting$ = new BehaviorSubject<Boolean>(false);
  private _creditProcess$ = new BehaviorSubject<Boolean>(false);
  private _unsubscribe$ = new Subject<any>();
  genders:Array<any>=[{ "id" : "", "name" : ""}, 
                      {"id" : "male", "name":"MALE"},
                      {"id": "famale","name" :"FEMALE"}];
  accessToken : any = null;

  // pages call id
  backmainId = 'backmain/dashboard';  // after login main page
  mainHomeId = 'home';      // main home page
  tokenStoreKey = 'loginAccessToken';      // main home page
  userTokenKey = 'userToken';      // main home page
  ewalletTokenStoreKey = 'eWalletToken';      // main home page
  loginId ='login';
  private _currentRoute$  = new BehaviorSubject<String>('');
  isMobile : boolean;

  constructor(
    private evgService : EvgApiService,
  ) {
   }

  getPopupSize() {
    if (window.innerWidth < 500) {
      return "sm";
    } else if (window.innerWidth < 1100) {
      return "md";
    } else {
      return "lg";
    }
  }

  validateExpiry (input:string) {
    // ensure basic format is correct
    //console.log('validateExpiry input: ',input);
    if (input.match(/^(0[1-9]|1[0-2])?([0-9]{4}|[0-9]{2})$/)) {
    //if (input.match(/^((0[1-9]|1[0-2])\/d{2})$/)) {
      //const {0: month, 1: year} = input.split("/");
      ///^(0[1-9]|1[0-2])\/\d{2}$/.test(s);
  
      // get midnight of first day of the next month
      //const expiry = new Date(parseInt("20" + input.substring(2,4)), parseInt(input.substring(0,2)));
      const expiry = new Date(parseInt("20" + input.substring(2,4)), parseInt(input.substring(0,2)));
      const current = new Date();
      //console.log('validateExpiry input: expiry:, current:',input, expiry,current);
      //console.log('validateExpiry expiry.getTime():  current.getTime():',expiry.getTime(), current.getTime());
      
      return expiry.getTime() > current.getTime();
      
    } else return false;
  }

  validateExpiryMMYY (input:string) {
    //console.log('validateExpiryMMYY input: ',input);
    //if (input.match(/^(0[1-9]|1[0-2])?([0-9]{4}|[0-9]{2})$/)) {
    if (input.match(/^(0[1-9]|1[0-2])\/([0-9]{2})$/)) {
      //const expiry = new Date(parseInt("20" + input.substring(2,4)), parseInt(input.substring(0,2)));
      const expiry = new Date(parseInt("20" + input.substring(3,5)), parseInt(input.substring(0,2)));
      const current = new Date();
      //console.log('validateExpiryMMYY input: expiry:, current:',input, expiry,current);
      return expiry.getTime() > current.getTime();
      
    } else return false;
  }

  isLoggedIn() {
    return this._user$.value ? true : false;
  }

  IsEwalletLoggedIn() {
    return this._ewallet$.value ? true : false;
  }

  isOnMobile() {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))? true : false;
  }


  get cart() : Observable<CartItem[]>{
    return this._cart_items$.asObservable();
  } 
  

  get user() : Observable<User>{
    return this._user$.asObservable();
  }

  get creditProcess() : Observable<Boolean>{
    return this._creditProcess$.asObservable();
  }

  get isPrinting() : Observable<Boolean>{
    return this._isPrinting$.asObservable();
  }

  get admins() : any{
    return this._admins$.value;
  }

  get accounting() : any{
    return this._accounting$.value;
  }
  get supports() : any{
    return this._supports$.value;
  }

  get tax_rate() : Observable<User>{
    return this._tax_rate$.asObservable();
  }

  get messages() : Observable<Message[]>{
    return this._messages$.asObservable();
  }

  get currentRoute() : Observable<String>{
    return this._currentRoute$.asObservable();
  }

  get mode() : Observable<String>{
    return this._mode$.asObservable();
  }

  get rankName() : Observable<String>{
    return this._rankName$.asObservable();
  }

  get ewallet() : Observable<User>{
    return this._ewallet$.asObservable();
  }

  get ewalletToken() : string
  {
    return localStorage.getItem(this.ewalletTokenStoreKey);
  }

  setUser(user : User){
    this._user$.next(user);
  }

  setCreditProcess(process){
    this._creditProcess$.next(process);
  }

  setIsPrinting(process){
    this._isPrinting$.next(process);
  }

  setAdmins(admins : any[]){
    this._admins$.next(admins);
  }

  setAccounting(accounting : any[]){
    this._accounting$.next(accounting);
  }

  setSupports(supports : any[]){
    this._supports$.next(supports);
  }

  setCurrentRoute(route : String){
    this._currentRoute$.next(route);
  }

  setMode(mode : String){
    this._mode$.next(mode);
  }

  setRankName(rankName : String){
    this._rankName$.next(rankName);
  }


  modifyCart(item :CartItem){
    let cart_items = this._cart_items$.value;

    let qty = item.nums;
    var index = cart_items.findIndex(cart_item => {
      return cart_item.pId === item.pId
    });

    if(index != null){
      
      cart_items[index].nums = cart_items[index].nums + qty;
    }

    console.log("INDEX",index);
  }

  setEWallet(ewallet : User){
    if(ewallet){
      localStorage.setItem(this.ewalletTokenStoreKey, "loggedIn");
    }else{
      localStorage.setItem(this.ewalletTokenStoreKey, "loggedOut");
    }
    this._ewallet$.next(ewallet);
  }

  logout(){
    this.setUser(null);
    this.setEWallet(null);
    this.accessToken = null;
    localStorage.setItem(this.tokenStoreKey, null);
    localStorage.setItem(this.userTokenKey, null);
    localStorage.setItem("mode", "")
    localStorage.setItem(this.ewalletTokenStoreKey, "loggedOut");
  }

  eWalletLogout(){
    localStorage.setItem(this.ewalletTokenStoreKey, "loggedOut");
    this.setEWallet(null);
  }


  getULevelName(type : number){
    switch (type) {
      case 0: return "Qualified Dealer";
      case 1: return "Qualified Consultant";
      case 2: return "Qualified Director";
      case 3: return "Qualified Executive";
      case 4: return "Qualified Sapphire";
      case 5: return "Qualified Diamond";
      case 6: return "Qualified Crown";
      default: return "Qualified Dealer";
    }
  }
  
  getRankKey(type:number) {
    switch (type) {
      case 0: return "QDEALER";
      case 1: return "QCONSULTANT";
      case 2: return "QDISTRIBUTOR";
      case 3: return "QEXECUTIVE";
      case 4: return "QSAPPHIREDISTRIBUTOR";
      case 5: return "QDIAMONDDISTRIBUTOR";
      case 6: return "QCROWNDISTRIBUTOR";
      default: return "QDEALER";
    }
  }

  getPaymentType(type : number){
    switch (type) {
      case 0: return "CREDITCARDONLY";
      case 1: return "EVCOINSLABEL";
      case 2: return "BYPAYPAL";
      case 3: return "BYSTRIPE";
      default: return "Unknown";
    }
  }
 

  getNextRankPercentage(rank,pTimes,downlines = []){
      var rank_0 = 0;
      var rank_1 = 0;
      var rank_2 = 0;
      var rank_3 = 0;
      var rank_4 = 0;
      var rank_5 = 0;
      var rank_6 = 0;

      downlines.forEach(downline => {
        switch (downline.uLevel) {
          case 0: downline.pTimes > 0 ? rank_0++ : rank_0 = rank_0;
          break;
          case 1: downline.pTimes > 0 ? rank_1++ : rank_1 = rank_1;
          break;
          case 2: rank_2++;
          break;
          case 3: rank_3++;
          break;
          case 4: rank_4++;
          break;
          case 5: rank_5++;
          break;
          case 6: rank_6++;
          break;
          default: rank_0++;
        }
      });

      if(rank == 0 && pTimes == 0){ // Member
        return (rank_0 / 1);
      }else if(rank == 0 && pTimes > 0){ //Dealer
        return (rank_0 / 2);
      }else if(rank == 1){  // Consultant
        return (rank_1 / 2);
      }else if(rank == 2){  // Distributor
        return (rank_2 / 3);
      }else if(rank == 3){  // Executive 
        return (rank_3 / 4);
      }else if(rank == 4){
        return (rank_4 / 5);
      }else if(rank == 5){
        return (rank_5 / 5);
      }else if(rank == 6){
        return 0;
      }
  }

  reloadUser(){
    this.evgService.getUser({id : this._user$.value.id}).subscribe(resp => {
      if(resp.data[0]){
        this.setUser(resp.data[0].results);
        if(this.ewalletToken == 'loggedIn'){
          this.setEWallet(resp.data[0].results);
        }
        else{
          this.setEWallet(null);
        }
      }
    });
  }

  refreshCart(){
    this.evgService.getCart({}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              if(successResponse.success){
                this._cart_items$.next(successResponse.data[0].results);
              }
          },
          (errorResult) => {
            console.log("System Error", errorResult);
          }
    );
  }

  reloadNewMessages(){
    this.evgService.getNewMessages({}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              if(successResponse.success){
                this._messages$.next(successResponse.data[0].results);
              }
          },
          (errorResult) => {
            console.log("System Error", errorResult);
          }
    );
  }

  reloadTaxRate(){
    this.evgService.getTaxRates({}).subscribe( resp => {
      if (resp) {
        let tax_rates = resp.data[0].results;
        if(this._user$.value.Province){
          this._tax_rate$.next(tax_rates.find(rate => {
            return rate.state_name.toUpperCase() === this._user$.value.Province.toUpperCase()
          }));
        }else{
          this._tax_rate$.next(tax_rates[0]);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
    });
  }

  getAssetRange(inTokens) {

    if (inTokens < 1) {
      return "{0}";
    } else if (inTokens <= 100) {
      return "{1~100}";
    } else if (inTokens <= 300) {
      return "{101~300}";
    } else if (inTokens <= 600) {
      return "{301~600}";
    } else if (inTokens <= 1000) {
      return "{601~1000}";
    } else {
      return "{1000+}";
    }
  }
  getAssetImage(inTokens) {
    if (inTokens < 1) {
      return "assets/images/team/byteToken0.png";
    } else if (inTokens <= 100) {
      return "assets/images/team/byteToken1.png";
    } else if (inTokens <= 300) {
      return "assets/images/team/byteToken2.png";
    } else if (inTokens <= 600) {
      return "assets/images/team/byteToken3.png";
    } else if (inTokens <= 1000) {
      return "assets/images/team/byteToken4.png";
    } else {
      return "assets/images/team/byteToken5.png";
    }
  }

}
