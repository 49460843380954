import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Order } from 'src/app/modules/common/Order';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  private _unsubscribe$ = new Subject<any>();
  public newOrders = [];
  public myOrders = [];
  public teamNewOrders = [];
  public teamOrders = [];
  public downlines = [];
  public downlineRanks = [];
  public team = [];
  public news = null;
  public user : User = new User();
  public rankName : string = "Member";
  public bulletinRanks = null;
  public rankAdvancements = null;
  public myRankInfo:any={};

  constructor(
    public translate : TranslateConfigService,
    private evgApiService: EvgApiService,
    private responseHandlerService : ResponseHandlerService,
    public shareinfoService : ShareinfoService,
    private router: Router,
    public sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getMyDownLinelist();
    this.getTeamData();
    this.getNews();
    this.getRankPromotedUsers();
    this.getPerformanceGrowthBulletinRanks();
    this.getMyRankInfo();
  }

  getMyRankName(){
    this.shareinfoService.rankName.pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (rankName: any) => {
            this.rankName = rankName;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
            
            this.getMyRankName();
            
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getMyDownLinelist(){
    this.evgApiService.getMyDownLinelist({}).pipe( takeUntil(this._unsubscribe$) )
    .subscribe(
      (successResponse: any) => {
          if(successResponse.success){
            this.downlines = successResponse.data[0].results;
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }
    );
  }

  getOrders()
  {
    this.evgApiService.findNewOrderGroups({}).pipe( takeUntil(this._unsubscribe$) )
    .subscribe(
      (successResponse: any) => {
          if(successResponse.success){
            this.newOrders = successResponse.data[0].results;
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }
    );

    this.evgApiService.getMyOrders({}).pipe( takeUntil(this._unsubscribe$) )
    .subscribe(
      (successResponse: any) => {
          if(successResponse.success){
            this.myOrders = successResponse.data[0].results;
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }
    );

    this.evgApiService.getTeamNewOrders({}).pipe( takeUntil(this._unsubscribe$) )
    .subscribe(
      (successResponse: any) => {
          // console.log("Team New Orders", successResponse);
          if(successResponse.success){
            this.teamNewOrders = successResponse.data[0].results;
            
            var _this = this;
            this.teamNewOrders = this.teamNewOrders.filter(function(order) {
                return _this.downlineRanks.includes( order.id) && order.orders > 0;
            });
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }
    );

    this.evgApiService.getTeamOrders({}).pipe( takeUntil(this._unsubscribe$) )
    .subscribe(
      (successResponse: any) => {
          // console.log("Team Orders", successResponse);
          if(successResponse.success){
            this.teamOrders = successResponse.data[0].results;
            var _this = this;
            this.teamOrders = this.teamOrders.filter(function(order) {
                return _this.downlineRanks.includes( order.id) && order.orders > 0;
            });
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }
    );

    
  }

  getNews()
  {
    this.evgApiService.getNews( {} ).subscribe(resp => {
      if (resp.success) {
        this.news = resp.data[0].results;

        if(this.news.length > 0){
          this.news = this.news[0];
        }
        
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getRankPromotedUsers()
  {
    this.evgApiService.getRankPromotedUsers( {} ).subscribe(resp => {
      if (resp.success) {
        this.rankAdvancements = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getPerformanceGrowthBulletinRanks()
  {
    this.evgApiService.getPerformanceGrowthBulletinRanks( {} ).subscribe(resp => {
      if (resp.success) {
        this.bulletinRanks = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }
  openPage(pId) {
    this.router.navigate([pId]);
  }
  
  getTeamData()
  {

    this.evgApiService.getTeam([])
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp.success) {
          if(resp.data[0]){
            if(resp.data[0].teamtree){
              this.downlineRanks = resp.data[0].teamtree;
              if(resp.data[0].teamtree.length > 0){
                const result = this.downlineRanks.filter(data => data.id != this.user.id);
                var tree = this.sharedUtilService.unflatten(this.sharedUtilService.mapTeamData(this.user,result));
                this.downlineRanks = [];
                this.getTreeChildren(tree[0]);
              }else{
                this.downlineRanks = [];
              }
              this.getOrders();
            }
    

          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  mapData(data)
  {
    let downlineIds = [];

    data.forEach(element => {
      downlineIds.push(element.id);
    });

    return downlineIds;
  }

  getTreeChildren(node)
  {
    if(node['childs'].length > 0){
      for (const child of node.childs) {
        this.downlineRanks.push(child.id)
        this.getTreeChildren(child);
      }
    }else{
      return true;
    }
  }

  getMyRankInfo(){
    this.evgApiService.getMyRankInfo({}).subscribe(resp => {
      if (resp.success) {
        this.myRankInfo = resp.data[0].results;
        console.log('getMyRankInfo myRankInfo :',this.myRankInfo);
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

}
